import axios from 'axios';
import { sortByAB } from '../utils/data/other';

const API_URL = process.env.REACT_APP_API_KEY || 'http://localhost:3002/api';

export async function getLeadsApi(setLeads) {
  const result = await getLeads();
  if (result.status === 200) {
    setLeads(result.data.message);
  }
}

export async function getLeadsWithFilter(from = 0, to, filters, sortBy) {
  const config = {
    method: 'get',
    url: `${API_URL}/contact-form/electric-up`,
    withCredentials: true,
  };
  return axios(config)
    .then((response) => {
      let error = response?.data?.error ?? false;
      if (!error) {
        let results = response.data.message;
        let totalResults = response.data.message.length;
        filters &&
          filters.forEach((filter) => {
            results = results.filter((product) => {
              if (filter.id === 'responsibleId') {
                return product[filter.id] === filter.value;
              }
              if (filter.id === 'status') {
                return product.status?.id === filter.value;
              }
              const name = product.contact.lastName + " " + product.contact.firstName
              return (
                (product.cui && product.cui.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.companyName && product.companyName.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.address && product.address.search(new RegExp(filter.value, 'i')) >= 0) ||
                (name && name.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.seasonality && product.seasonality.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.kWh && product.kWh.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.geoAddress && product.geoAddress.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.locationCoordinates && product.locationCoordinates.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.status?.name && product.status?.name.search(new RegExp(filter.value, 'i')) >= 0))
            });
          });

        sortBy &&
          sortBy.forEach((sort) => {
            let index = sort.desc ? -1 : 1;
            switch (sort.id) {
              case 'cui':
                results = results.sort((a, b) => a.cui < b.cui ? -index : index);
                break;

              case 'companyName':
                results = results.sort((a, b) => a.companyName < b.companyName ? -index : index);
                break;

              case 'address':
                results = results.sort((a, b) => sortByAB(a.address, b.address, index, sort));
                break;

              case 'message':
                results = results.sort((a, b) => sortByAB(a.message, b.message, index, sort));
                break;

              case 'contact':
                results = results.sort((a, b) => {
                  let nameA = a.contact.lastName + " " + a.contact.firstName
                  let nameB = b.contact.lastName + " " + b.contact.firstName
                  return sortByAB(nameA, nameB, index, sort)
                });
                break;
              case 'locationCoordinates':
                results = results.sort((a, b) => {
                  return sortByAB(a.locationCoordinates, b.locationCoordinates, index, sort)
                });
                break;

              case 'seasonality':
                results = results.sort((a, b) => sortByAB(a.seasonality, b.seasonality, index, sort));
                break;

              case 'assembly':
                results = results.sort((a, b) => sortByAB(a.assembly, b.assembly, index, sort));
                break;

              case 'kWh':
                results = results.sort((a, b) => sortByAB(a.kWh, b.kWh, index, sort));
                break;


              case 'geoAddress':
                results = results.sort((a, b) => sortByAB(a.geoAddress, b.geoAddress, index, sort));
                break;

              case 'status':
                results = results.sort((a, b) => sortByAB(a.status?.name ?? ' ', b.status?.name ?? ' ', index, sort));
                break;

              case 'createdAt':
                results = results.sort((a, b) => sortByAB(a.createdAt, b.createdAt, index, sort));

              default:
                break;
            }
          });

        return {
          data: results.slice(from, to),
          total: results.length,
          totalResults: totalResults,
        };
      }
    })
    .catch((error) => console.error(error));
}

export async function updateLeadApi(data, id, csrfToken) {
  const config = {
    method: 'patch',
    url: `${API_URL}/contact-form/electric-up/${id}`,
    withCredentials: true,
    data: data,
    headers: {
      'csrf-token': csrfToken,
    },
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function deleteLead(id, csrfToken) {
  const config = {
    method: 'delete',
    url: `${API_URL}/contact-form/electric-up/${id}`,
    withCredentials: true,
    headers: {
      'csrf-token': csrfToken,
    },
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function getLead(id) {
  const config = {
    method: 'get',
    url: `${API_URL}/contact-form/electric-up/${id}`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}

export async function getLeads() {
  const config = {
    method: 'get',
    url: `${API_URL}/contact-form/electric-up`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}
