import axios from 'axios';
import { sortByAB } from '../utils/data/other';

const API_URL = process.env.REACT_APP_API_KEY || 'http://localhost:3002/api';

// Questions
export async function getQuestionsOrCategoriesWithFilter(from = 0, to, filters, sortBy, type) {
  const config = {
    method: 'get',
    url: `${API_URL}/questionsAndAnswers/${type}`,
    withCredentials: true,
  };

  return axios(config)
    .then((response) => {
      let error = response?.data?.error ?? false;
      if (!error) {
        let results = response.data.message;
        let totalResults = response.data.message.length;
        filters &&
          filters.forEach((filter) => {
            results = results.filter((product) => {
              return (
                (product.question && product.question.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product?.category?.name && product.category.name.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.name && product.name.search(new RegExp(filter.value, 'i')) >= 0)
              );
            });
          });

        sortBy &&
          sortBy.forEach((sort) => {
            let index = sort.desc ? -1 : 1;
            switch (sort.id) {
              case 'name':
                results = results.sort((a, b) =>
                  a.name < b.name ? -index : index
                );
                break;
              case 'question':
                results = results.sort((a, b) =>
                  a.question < b.question ? -index : index
                );
                break;
              case 'questionCategory':
                results = results.sort((a, b) =>
                  a.category.name < b.category.name ? -index : index
                );
                break;
              default:
                break;
            }
          });

        return {
          data: results.slice(from, to),
          total: results.length,
          totalResults: totalResults,
        };
      };
    })
    .catch((error) => console.error(error));
}

export async function getQuestionsApi(id) {
  if (id) {
    const result = await getQuestions(id);
    if (result.status === 200) {
      return result;
    }
  }
}

export async function getQuestionsByCategoryName(categoryName) {
  const config = {
    method: 'get',
    url: `${API_URL}/questionsAndAnswers/questions`,
    withCredentials: true,
    params: {
      categoryName: categoryName
    }
  }
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}

export async function getQuestions(id) {
  const config = {
    method: 'get',
    url: `${API_URL}/questionsAndAnswers/questions/${id}`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}

export async function updateQuestion(id, data) {
  const config = {
    method: 'patch',
    url: `${API_URL}/questionsAndAnswers/questions/${id}`,
    withCredentials: true,
    data: data,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}

export async function createQuestion(data) {
  const config = {
    method: 'post',
    url: `${API_URL}/questionsAndAnswers/questions`,
    withCredentials: true,
    data: data,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}

export async function deleteQuestion(id, csrfToken) {
  const config = {
    method: 'delete',
    url: `${API_URL}/questionsAndAnswers/questions/${id}`,
    withCredentials: true,
    headers: {
      'csrf-token': csrfToken,
    },
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}
// End Questions

// Question Answers
export async function getQuestionAnswersApi(id) {
  if (id) {
    const result = await getQuestionAnswers(id);
    if (result.status === 200) {
      return result;
    }
  }
}

export async function getQuestionAnswers(id) {
  const config = {
    method: 'get',
    url: `${API_URL}/questionsAndAnswers/questionAnswers/${id}`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}

export async function createQuestionAnswers(id, data) {
  const config = {
    method: 'post',
    url: `${API_URL}/questionsAndAnswers/questionAnswers/${id}`,
    withCredentials: true,
    data,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}

export async function getQuestionAnswersByQuestionIdAndQAId(qaId, questionId) {
  const config = {
    method: 'get',
    url: `${API_URL}/questionsAndAnswers/questionAnswers`,
    withCredentials: true,
    params: {
      questionAndAnswersId: qaId,
      questionId: questionId,
    }
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}
// End Question Answers

// Question Categories
export async function getQuestionCategories() {
  const config = {
    method: 'get',
    url: `${API_URL}/questionsAndAnswers/questionCategories`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}

export async function getQuestionCategory(id) {
  const config = {
    method: 'get',
    url: `${API_URL}/questionsAndAnswers/questionCategories/${id}`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}

export async function updateQuestionCategory(id, data) {
  const config = {
    method: 'patch',
    url: `${API_URL}/questionsAndAnswers/questionCategories/${id}`,
    withCredentials: true,
    data: data,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}

export async function createQuestionCategory(data) {
  const config = {
    method: 'post',
    url: `${API_URL}/questionsAndAnswers/questionCategories`,
    withCredentials: true,
    data: data,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}

export async function deleteQuestionCategory(id, csrfToken) {
  const config = {
    method: 'delete',
    url: `${API_URL}/questionsAndAnswers/questionCategories/${id}`,
    withCredentials: true,
    headers: {
      'csrf-token': csrfToken,
    },
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}
// End Question Categories