import axios from 'axios';

const API_URL = process.env.REACT_APP_API_KEY || 'http://localhost:3002/api';

export async function getStatusesApi(setStatuses) {
    const result = await getStatuses();
    if (result.status === 200) {
      setStatuses(result.data.message);
    }
}

export async function getFilteredStatuses(from = 0, to, filters, sortBy) {
  const config = {
    method: 'get',
    url: `${API_URL}/status`,
    withCredentials: true,
  };
  return axios(config)
    .then((response) => {
      let error = response.data.error
      if(!error){
        let results = response.data.message;
        let totalResults = response.data.message.length;
        filters &&
          filters.forEach((filter) => {
            results = results.filter((product) => {
              return (
                (product.name && product.name.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.tableName && product.tableName.search(new RegExp(filter.value, 'i'))) >= 0 ||
                (product.order && product.order.toString().search(new RegExp(filter.value, 'i'))) >= 0 ||
                (product.icon && product.icon.search(new RegExp(filter.value, 'i')) >= 0)
              );
            });
          });

        sortBy &&
          sortBy.forEach((sort) => {
            let index = sort.desc ? -1 : 1;
            switch (sort.id) {
              case 'name':
                results = results.sort((a, b) =>
                  a.name < b.name ? -index : index
                );
              break;
              case 'tableName':
                results = results.sort((a, b) =>
                  a.tableName < b.tableName ? -index : index
                );
              break;
              case 'order':
                results = results.sort((a, b) =>
                  a.order < b.order ? -index : index
                );
              break;
              case 'icon':
                results = results.sort((a, b) => {
                  if (!a.icon && !b.icon) return 0; // Both are empty/null
                  if (!a.icon) return index; // a is empty/null, move it to end
                  if (!b.icon) return -index; // b is empty/null, move it to end
                  return a.icon < b.icon ? -index : index; // Both have values, sort as usual
                });
              break;
              default:
              break;
            }
          });

        return {
          data: results.slice(from, to),
          total: results.length,
          totalResults: totalResults,
        };
      }
    })
    .catch((error) => console.error(error));
}

export async function createStatusApi(data, csrfToken) {
  const config = {
    method: 'post',
    url: `${API_URL}/status`,
    withCredentials: true,
    data: data,
    headers: {
      'csrf-token': csrfToken,
    },
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function updateStatusApi(data, id, csrfToken) {
  const config = {
    method: 'patch',
    url: `${API_URL}/status/${id}`,
    withCredentials: true,
    data: data,
    headers: {
      'csrf-token': csrfToken,
    },
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function deleteStatusApi(id, csrfToken) {
  const config = {
    method: 'delete',
    url: `${API_URL}/status/${id}`,
    withCredentials: true,
    headers: {
      'csrf-token': csrfToken,
    },
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function getStatuses() {
    const config = {
      method: 'get',
      url: `${API_URL}/status`,
      withCredentials: true,
    };
    return await axios(config)
      .then((response) => {
        return response;
      })
      .catch((error) => console.error(error));
  }
  
  export async function getStatus(id) {
    const config = {
      method: 'get',
      url: `${API_URL}/status/${id}`,
      withCredentials: true,
    };
    return await axios(config)
      .then((response) => {
        return response;
      })
      .catch((error) => console.error(error));
  }

  export async function getStatusByName(name) {
    const config = {
      methos: 'get',
      url: `${API_URL}/status`,
      withCredentials: true,
      params: {
        name: name
      }
    };
    return await axios(config)
      .then((response) => {
        return response;
      })
      .catch((error) => console.error(error));
  }
