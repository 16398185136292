import React, { useState, useEffect, useContext } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { storageCsrf } from '../../../api/auth';
import { getLead, updateLeadApi } from '../../../api/electricUp';
import Loader from '../../../features/loader';
import UserContext from '../../../utils/context/userContext';
import Breadcrumb from '../../common/breadcrumb';
import Notes from '../../notes/notes-page';
import QuestionsAndAnswers from '../../questionsAndAnswers/questions-and-answers';


export default function LeadsElectricUpDetail() {
    const [loading, setLoading] = useState(true);
    const [lead, setLead] = useState([]);
    const loggedUser = useContext(UserContext);

    const { id } = useParams();
    
    useEffect(() => {
        setLoading(true);
        async function handleLead() {
            getLead(id).then(async (result) => {
                setLead(result.data.message);
                if(!result?.data?.message?.responsibleId) {
                    let csrfToken;
                    if (sessionStorage.getItem('csrfToken')) {
                        csrfToken = sessionStorage.getItem('csrfToken');
                    } else {
                        csrfToken = await storageCsrf();
                    }
                    let data = {
                        _csrf: csrfToken,
                        responsibleId: loggedUser.id,
                    };
                    await updateLeadApi(data, id, csrfToken).then(() => {
                        handleLead();
                    });
                }
                setLoading(false);
            })
        }
        handleLead();

        document.querySelector('body').classList.add('loaded');
    }, [id, lead.responsibleId, loggedUser.id]);

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Tabs defaultActiveKey={'eligibility'} mountOnEnter={true} unmountOnExit={true} id={'detailTab'} className={'mb-3'} variant={'pills'}>
                        <Tab eventKey={'eligibility'} title={'Eligibilitate'}>
                            <Breadcrumb
                                current='ElectricUp Eligibility'
                                paths={[
                                    {
                                        name: 'Dashboard',
                                        url: '/dashboard',
                                    },
                                    {
                                        name: 'Sales',
                                        url: '#',
                                    },
                                    {
                                        name: 'Formular de contact',
                                        url: '#',
                                    },
                                    {
                                        name: 'ElectricUp',
                                        url: '/dashboard/electric-up/leads',
                                    },
                                    {
                                        name: 'Eligibility',
                                        url: '#',
                                    },
                                ]}
                            />
                            {lead ?
                                <>
                                    <QuestionsAndAnswers
                                        qaSource={lead}
                                    >
                                    </QuestionsAndAnswers>
                                </>
                                : ''
                            }
                        </Tab>
                        <Tab eventKey={'notes'} title={'Notes'}>
                            <Breadcrumb
                                current='ElectricUp Notes'
                                paths={[
                                    {
                                        name: 'Dashboard',
                                        url: '/dashboard',
                                    },
                                    {
                                        name: 'Sales',
                                        url: '#',
                                    },
                                    {
                                        name: 'Formular de contact',
                                        url: '#',
                                    },
                                    {
                                        name: 'ElectricUp',
                                        url: '/dashboard/electric-up/leads',
                                    },
                                    {
                                        name: 'Notes',
                                        url: '#',
                                    },
                                ]}
                            />
                            <Notes
                                noteSource={lead}
                            ></Notes>
                        </Tab>
                    </Tabs>
                </>
            )
            }
        </>
    )
}