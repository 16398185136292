import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Form, Button, Collapse } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Breadcrumb from '../../common/breadcrumb';
import DeleteConfirmModal from '../../../features/modals/delete-confirm-modal';
import Loader from '../../../features/loader';
import PNotify from '../../../features/elements/p-notify';

import { getUser } from '../../../api/index';
import { getRolesApi } from '../../../api/role';
import { storageCsrf } from '../../../api/auth';
import { deleteAccount, updateAccount } from '../../../api/user';

export default function UserDetail() {
  const [user, setUser] = useState(null);
  const [userOld, setUserOld] = useState(null);
  const [roles, setRoles] = useState(null);
  const [loading, setLoading] = useState(true);
  const [resetPwd, setResetPwd] = useState(false);
  const [pwd, setPwd] = useState('');
  const [pwdConfirm, setPwdConfirm] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getRolesApi(setRoles);
    getUser(id).then((result) => {
      setUser(result.data.message);
      setUserOld(result.data.message);
      setLoading(false);
    });
    document.querySelector('body').classList.add('loaded');
  }, [id, setUser]);

  async function updateUser(e) {
    e.preventDefault();
    let csrfToken;
    if (pwd !== pwdConfirm) {
      return toast(
        <PNotify
          title='Error'
          icon='fas fa-check'
          text='Passwords must match!'
        />,
        {
          containerId: 'default',
          className: 'notification-danger',
        }
      );
    }
    if (sessionStorage.getItem('csrfToken')) {
      csrfToken = sessionStorage.getItem('csrfToken');
    } else {
      csrfToken = await storageCsrf();
    }
    let data = {
      _csrf: csrfToken,
    };
    if (userOld.firstName !== e.target.firstName.value) {
      data.firstName = e.target.firstName.value;
    }
    if (userOld.lastName !== e.target.lastName.value) {
      data.lastName = e.target.lastName.value;
    }
    if (userOld.email !== e.target.email.value) {
      data.email = e.target.email.value;
    }
    if (userOld.role.id !== e.target.role.value) {
      data.roleId = e.target.role.value;
    }
    if (pwd !== '' && pwd === pwdConfirm) {
      data.password = pwd;
    }
    const result = await updateAccount(data, id, csrfToken);
    if (result.status === 200 && result.data.error === 0) {
      navigate(`${process.env.PUBLIC_URL}/dashboard/users`);
      return toast(
        <PNotify
          title='Success'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-success',
        }
      );
    } else {
      return toast(
        <PNotify
          title='Error'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-danger',
        }
      );
    }
  }

  function deleteUserApi(e) {
    e.preventDefault();
    setOpenModal(true);
  }

  async function deleteConfirm(result) {
    setOpenModal(false);
    if (result) {
      navigate(`${process.env.PUBLIC_URL}/dashboard/users`);
      let csrfToken;
      if (sessionStorage.getItem('csrfToken')) {
        csrfToken = sessionStorage.getItem('csrfToken');
      } else {
        csrfToken = await storageCsrf();
      }
      const checker = await deleteAccount(id, csrfToken);
      if (checker.status === 200 && checker.data.error === 0) {
        navigate(`${process.env.PUBLIC_URL}/dashboard/roles`);
        return toast(
          <PNotify
            title='Success'
            icon='fas fa-check'
            text={checker.data.message}
          />,
          {
            containerId: 'default',
            className: 'notification-success',
          }
        );
      } else {
        return toast(
          <PNotify
            title='Error'
            icon='fas fa-check'
            text={checker.data.message}
          />,
          {
            containerId: 'default',
            className: 'notification-danger',
          }
        );
      }
    }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Breadcrumb
            current='Edit User'
            paths={[
              {
                name: 'Home',
                url: '/',
              },
              {
                name: 'users',
                url: `/dashboard/users`,
              },
              {
                name: 'edit',
                url: `/edit`,
              },
            ]}
          />

          <Form
            className='ecommerce-form'
            action='#'
            method='post'
            onSubmit={updateUser}
          >
            <Row>
              <Col>
                <Card className='card-modern card-big-info'>
                  <Card.Body>
                    <Row>
                      <Col lg='2-5' xl='1-5'>
                        <i className='card-big-info-icon bx bx-user-circle'></i>
                        <h2 className='card-big-info-title'>Account Info</h2>
                        <p className='card-big-info-desc'>
                          Add here the user account info.
                        </p>
                      </Col>
                      <Col lg='3-5' xl='4-5'>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            First Name
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type='text'
                              name='firstName'
                              className='form-control-modern'
                              value={user.firstName}
                              onChange={(e) =>
                                setUser({ ...user, firstName: e.target.value })
                              }
                              required
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Last Name
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type='text'
                              name='lastName'
                              className='form-control-modern'
                              value={user.lastName}
                              onChange={(e) =>
                                setUser({ ...user, lastName: e.target.value })
                              }
                              required
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Email
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type='email'
                              name='email'
                              className='form-control-modern'
                              value={user.email}
                              onChange={(e) =>
                                setUser({ ...user, email: e.target.value })
                              }
                              required
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Password Reset
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Check
                              className='checkbox-default'
                              custom
                              id='checkboxExample1'
                              checked={resetPwd}
                              onChange={(e) => {
                                setResetPwd(e.target.checked);
                              }}
                              label='Do you want to reset password?'
                            />
                          </Col>
                        </Form.Group>
                        <Collapse in={resetPwd}>
                          <Form.Group
                            style={{ margin: '0 -15px' }}
                            className='py-0 mb-0'
                          >
                            <Form.Group
                              as={Row}
                              className='align-items-center mx-0 pt-3'
                            >
                              <Col
                                as={Form.Label}
                                lg={5}
                                xl={3}
                                className='control-label text-lg-right mb-lg-0'
                              >
                                Password
                              </Col>
                              <Col lg={7} xl={6}>
                                <Form.Control
                                  type='password'
                                  className='form-control-modern'
                                  value={pwd}
                                  onChange={(e) => setPwd(e.target.value)}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              className='align-items-center mx-0 mb-3'
                            >
                              <Col
                                as={Form.Label}
                                lg={5}
                                xl={3}
                                className='control-label text-lg-right mb-lg-0'
                              >
                                Password Confirm
                              </Col>
                              <Col lg={7} xl={6}>
                                <Form.Control
                                  type='password'
                                  className='form-control-modern'
                                  value={pwdConfirm}
                                  onChange={(e) =>
                                    setPwdConfirm(e.target.value)
                                  }
                                />
                              </Col>
                            </Form.Group>
                          </Form.Group>
                        </Collapse>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Manage Role
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              as='select'
                              className='form-control-modern'
                              name='role'
                              onChange={(e) => {
                                setUser({ ...user, role: e.target.value });
                              }}
                              defaultValue={user.role.name}
                            >
                              {roles
                                ? roles.map((data) => {
                                    return (
                                      <option key={data.name} value={data.id}>
                                        {data.name}
                                      </option>
                                    );
                                  })
                                : ''}
                            </Form.Control>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className='action-buttons'>
              <Col md='auto' className='col-6'>
                <Button
                  type='submit'
                  className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
                  variant='primary'
                >
                  <i className='bx bx-save text-4 mr-2'></i> Save User
                </Button>
              </Col>
              <Col md='auto' className='col-6 px-md-0 mt-0'>
                <Button
                  as={Link}
                  to={`${process.env.PUBLIC_URL}/dashboard/users`}
                  className='btn-px-4 py-3 border font-weight-semibold text-color-dark line-height-1 d-flex h-100 align-items-center'
                  variant='light'
                >
                  Back
                </Button>
              </Col>
              <Col md='auto' className='col-6 ml-md-auto mt-3 mt-md-0'>
                <Button
                  href='#delete'
                  className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
                  variant='danger'
                  onClick={deleteUserApi}
                >
                  <i className='bx bx-trash text-4 mr-2'></i> Delete User
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}

        <DeleteConfirmModal isOpen={openModal} onClose={deleteConfirm} />
    </>
  );
}
