import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Collapse, Dropdown } from 'react-bootstrap';
import SlideToggle from 'react-slide-toggle';

import menuData from '../../utils/data/menu';

export default function Header({user}) {
  const [showUserBox, toggleUserBox] = useState(false);
  const [menu, toggleMenu] = useState(false);

  useEffect(() => {
    document.querySelector('body').addEventListener('click', onBodyClick);
  }, []);

  function onToggleUserBox(e) {
    e.preventDefault();
    toggleUserBox(!showUserBox);
  }

  function onBodyClick(e) {
    if (!e.target.closest('.userbox')) {
      toggleUserBox(false);
    }
  }

  function prevent(e) {
    e.preventDefault();
  }

  return (
    <header className='header header-nav-menu header-nav-links'>
      <div className='logo-container'>
        <Link to={`${process.env.PUBLIC_URL}`} className='logo'>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/logo-modern.png`}
            className='logo-image'
            width='110'
            height='30'
            alt=''
          />
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/logo-default.png`}
            className='logo-image-mobile'
            width='90'
            height='41'
            alt=''
          />
        </Link>
        <Button
          variant=''
          className='header-btn-collapse-nav d-md-none'
          onClick={() => toggleMenu(!menu)}
        >
          <i className='fas fa-bars'></i>
        </Button>

        <Collapse in={menu}>
          <div className='header-nav d-md-none'>
            <div className='header-nav-main header-nav-main-effect-1 header-nab-main-sub-effect-1 header-nav-main-square'>
              <nav>
                <ul className='nav nav-pills'>
                  {menuData.map((item, index) => (
                    <li
                      className={item.children ? 'dropdown' : ''}
                      key={`dropdown-${index}`}
                    >
                      {item.children ? (
                        <>
                          <SlideToggle collapsed={true}>
                            {({ onToggle, setCollapsibleElement }) => (
                              <>
                                <a
                                  href='#no'
                                  className='nav-link dropdown-toggle'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onToggle();
                                  }}
                                >
                                  {item.name}
                                  <i className='fas fa-caret-down'></i>
                                </a>

                                <ul
                                  className='dropdown-menu'
                                  ref={setCollapsibleElement}
                                >
                                  {item.children.map((subMenu, subId) => (
                                    <li
                                      className={
                                        subMenu.children
                                          ? 'dropdown-submenu'
                                          : ''
                                      }
                                      key={`dropdown-${index}-${subId}`}
                                    >
                                      {subMenu.children ? (
                                        <SlideToggle collapsed={true}>
                                          {({
                                            onToggle,
                                            setCollapsibleElement,
                                          }) => (
                                            <>
                                              <a
                                                href='#no'
                                                className='nav-link dropdown-toggle'
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  onToggle();
                                                }}
                                              >
                                                {subMenu.name}
                                                <i className='fas fa-caret-down'></i>
                                              </a>
                                              <ul
                                                className='dropdown-menu'
                                                ref={setCollapsibleElement}
                                              >
                                                {subMenu.children.map(
                                                  (ssubMenu, ssubId) => (
                                                    <li
                                                      key={`dropdown-${index}-${subId}-${ssubId}`}
                                                    >
                                                      <Link
                                                        to={
                                                          process.env
                                                            .PUBLIC_URL +
                                                          ssubMenu.url
                                                        }
                                                        className='nav-link'
                                                      >
                                                        {ssubMenu.name}
                                                      </Link>
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            </>
                                          )}
                                        </SlideToggle>
                                      ) : (
                                        <Link
                                          to={
                                            process.env.PUBLIC_URL + subMenu.url
                                          }
                                          className='nav-link'
                                        >
                                          {subMenu.name}
                                        </Link>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              </>
                            )}
                          </SlideToggle>
                        </>
                      ) : (
                        <Link
                          to={process.env.PUBLIC_URL + item.url}
                          className='nav-link'
                        >
                          {item.name}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </Collapse>

        <div className='header-nav show d-none d-md-block'>
          <div className='header-nav-main'></div>
        </div>
      </div>

      <div className='header-right'>
        {/* <ul className='notifications'>
          <li>
            <Dropdown>
              <Dropdown.Toggle as='a' className='notification-icon'>
                <i className='bx bx-bell'></i>
                <span className='badge'>3</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className='notification-menu'>
                <div className='notification-title'>
                  <span className='float-right badge badge-default'>3</span>{' '}
                  Alerts
                </div>

                <div className='content'>
                  <ul>
                    <li>
                      <a href='#log' className='clearfix' onClick={prevent}>
                        <div className='image'>
                          <i className='bx bx-dislike bg-danger'></i>
                        </div>
                        <span className='title'>Server is Down!</span>
                        <span className='message'>Just now</span>
                      </a>
                    </li>
                    <li>
                      <a href='#log' className='clearfix' onClick={prevent}>
                        <div className='image'>
                          <i className='bx bx-lock-alt bg-warning'></i>
                        </div>
                        <span className='title'>User Locked</span>
                        <span className='message'>15 minutes ago</span>
                      </a>
                    </li>
                    <li>
                      <a href='#log' className='clearfix' onClick={prevent}>
                        <div className='image'>
                          <i className='bx bx-wifi bg-success'></i>
                        </div>
                        <span className='title'>Connection Restaured</span>
                        <span className='message'>10/10/2017</span>
                      </a>
                    </li>
                  </ul>

                  <hr />

                  <div className='text-right'>
                    <Link
                      to={`${process.env.PUBLIC_URL}/pages/log-viewer`}
                      className='view-more'
                    >
                      View All
                    </Link>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul> */}

        <span className='separator'></span>

        <div id='userbox' className={`userbox ${showUserBox ? 'show' : ''}`}>
          <a href='#no' onClick={onToggleUserBox}>
            <span className='profile-picture profile-picture-as-text'>Me</span>
            <div className='profile-info profile-info-no-role'>
              <span className='name'>
                Salut, <strong className='font-weight-semibold'>{user.name}</strong>
              </span>
            </div>
            <i className='fas fa-chevron-down text-color-dark'></i>
          </a>

          <div className={`dropdown-menu ${showUserBox ? 'show' : ''}`}>
            <ul className='list-unstyled'>
              <li>
                <Link to={`${process.env.PUBLIC_URL}/logout`}>
                  <i className='bx bx-log-out'></i> Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}
