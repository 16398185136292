import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import Breadcrumb from '../../common/breadcrumb';
import { storageCsrf } from '../../../api/auth';
import { toast } from 'react-toastify';
import { createContact } from '../../../api/contact';
import PNotify from '../../../features/elements/p-notify';

export default function ContactCreate() {
  async function saveContact(event) {
    event.preventDefault();
    let csrfToken;
    if (sessionStorage.getItem('csrfToken')) {
      csrfToken = sessionStorage.getItem('csrfToken');
    } else {
      csrfToken = await storageCsrf();
    }
    let data = {
      firstName: event.target.firstName.value ?? '',
      lastName: event.target.lastName.value ?? '',
      fullAddress: event.target.fullAddress.value ?? '',
      email: event.target.email.value ?? '',
      phone: event.target.phone.value ?? '',
      typeOfContactId: event.target.typeOfContact.value ?? '',
      jobPosition: event.target.jobPosition.value ?? ' ',
      newsLetter: event.target.newsLetter.checked ? true : false,
      _csrf: csrfToken,
    };
    const result = await createContact(data);
    if (result.status === 200 && result.data.error === 0) {
      document.getElementById('myForm').reset();

      return toast(
        <PNotify
          title='Success'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-success',
        }
      );
    } else {
      return toast(
        <PNotify
          title='Error'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-danger',
        }
      );
    }
  }

  useEffect(() => {
    document.querySelector('body').classList.add('loaded');
  });

  return (
    <>
      <Breadcrumb
        current='Add Contacts'
        paths={[
          {
            name: 'Dashboard',
            url: '/dashboard',
          },
          {
            name: 'Contacts',
            url: '/dashboard/Contacts',
          },
          {
            name: 'New Contact',
            url: '/dashboard/Contacts/create',
          },
        ]}
      />

      <Form
        className='ensys-form form'
        action='#'
        method='post'
        onSubmit={saveContact}
        id='myForm'
      >
        <Row>
          <Col>
            <Card className='card-modern card-big-info'>
              <Card.Body>
                <Row>
                  <Col lg='2-5' xl='1-5'>
                    <i className='card-big-info-icon bx bxs-user-badge'></i>
                    <h2 className='card-big-info-title'>Contact Info</h2>
                    <p className='card-big-info-desc'>
                      Add here the Contact info.
                    </p>
                  </Col>
                  <Col lg='3-5' xl='4-5'>
                  <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Nume
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type='text'
                              maxLength='30'
                              className='form-control-modern'
                              name='firstName'
                              required
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Prenume
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type='text'
                              maxLength='30'
                              className='form-control-modern'
                              name='lastName'
                              required
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Adresa
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type='text'
                              maxLength='120'
                              className='form-control-modern'
                              name='fullAddress'
                              required
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            E-mail
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type='text'
                              maxLength='30'
                              className='form-control-modern'
                              name='email'
                              required
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Telefon
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type='text'
                              maxLength='30'
                              className='form-control-modern'
                              name='phone'
                              required
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Persoană Fizică
                          </Col>
                          <Col lg={1} xl={1}>
                            <Form.Control
                              id='persFizica'
                              type='radio'
                              name='typeOfContact'
                              value='persoana fizica'
                            ></Form.Control>
                          </Col>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Persoană Juridică
                          </Col>
                          <Col lg={1} xl={1}>
                            <Form.Control
                              id='persJuridica'
                              type='radio'
                              name='typeOfContact'
                              value='persoana juridica'
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Functie
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type='text'
                              maxLength='30'
                              className='form-control-modern'
                              name='jobPosition'
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Newsletter
                          </Col>
                          <Col lg={1} xl={1}>
                            <Form.Control
                              type='checkbox'
                              maxLength='1'
                              name='newsLetter'
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className='action-buttons'>
          <Col md='auto' className='col-12'>
            <Button
              type='submit'
              className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
              variant='primary'
            >
              <i className='bx bx-save text-4 mr-2'></i>Save Contact
            </Button>
          </Col>
          <Col md='auto' className='col-12 px-md-0 mt-3 mt-md-0'>
            <Button
              as={Link}
              to={`${process.env.PUBLIC_URL}/dashboard/contacts`}
              className='btn-px-4 py-3 border font-weight-semibold text-color-dark line-height-1 d-flex h-100 align-items-center'
              variant='light'
            >
              Back
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
