import React from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import Modal from "react-modal";

const modalStyles = {
    overlay: {
        background: 'rgba(11, 11, 11, .8)',
        zIndex: 99999
    },
    content: {
        left: '50%',
        top: '50%',
        bottom: 'auto',
        right: 'auto',
        outline: 'none',
        width: '600px',
        maxWidth: '100%',
        padding: 0,
        transform: 'translate(-50%, -50%)'
    }
};

Modal.setAppElement( '#app' );

export default function loggedDetailsModal ( { isOpen, onClose, details } ) {

    function closeModal ( result = false ) {
        onClose( result );
    }

    const keyValues = Object.entries(details).map(([key, value]) => {
        if (value && key != "responseMessage") {
          return (
            <tr key={key}>
                <td><b>{key}: </b></td>
                <td>{value}</td>
            </tr>
          );
        }

        return null;
    });

    return (
        <Modal
            isOpen={ isOpen }
            style={ modalStyles }
            onRequestClose={ () => closeModal( false ) }
        >
            <Card>
                <Card.Header>
                    <Card.Title>Modificari:</Card.Title>
                </Card.Header>
                <Card.Body>
                <h4>{details['responseMessage']}</h4>
                <div className="modal-wrapper d-flex justify-content-center">
                    <table>
                        <tbody>
                            {keyValues}
                        </tbody>
                    </table>
                </div>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col md={ 12 } className="text-right">
                            <Button
                                className="modal-confirm mr-2"
                                variant="primary"
                                onClick={ () => closeModal( false ) }
                            >Ok</Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Modal>
    )
}