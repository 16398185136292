import axios from 'axios';

const API_URL = process.env.REACT_APP_API_KEY || 'http://localhost:3002/api';

export async function getContactsApi(setContacts) {
  const result = await getContacts();
  if (result.status === 200) {
    setContacts(result.data.message);
  }
}

export async function getContactsWithFilter(from = 0, to, filters, sortBy) {
  const config = {
    method: 'get',
    url: `${API_URL}/contact`,
    withCredentials: true,
  };
  return axios(config)
    .then((response) => {
      let error = response?.data?.error ?? false;
      if(!error){
        let results = response.data.message;
        let totalResults = response.data.message.length;
        filters &&
          filters.forEach((filter) => {
            results = results.filter((product) => {
              return (
                (product.firstName && product.firstName.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.lastName && product.lastName.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.email && product.email.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.phone && product.phone.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.fullAddress && product.fullAddress.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.jobPosition && product.jobPosition.search(new RegExp(filter.value, 'i')) >= 0))
            });
          });
  
        sortBy &&
          sortBy.forEach((sort) => {
            let index = sort.desc ? -1 : 1;
            switch (sort.id) {
              case 'lastName':
                results = results.sort((a, b) =>
                  a.lastName < b.lastName ? -index : index
                );
                break;
              case 'firstName':
                results = results.sort((a, b) =>
                  a.firstName < b.firstName ? -index : index
                );
                break;
              case 'email':
                results = results.sort((a, b) =>
                  a.email < b.email ? -index : index
                );
                break;
              case 'phone':
                results = results.sort((a, b) =>
                  a.phone < b.phone ? -index : index
                );
                break;
              case 'typeOfContact':
                results = results.sort((a, b) =>
                  a.typeOfContact < b.typeOfContact ? -index : index
                );
                break;
              case 'newsLetter':
                results = results.sort((a, b) =>
                  a.newsLetter < b.newsLetter ? -index : index
                );
                break;
              case 'fullAddress':
                results = results.sort((a, b) =>{
                  // equal items sort equally
                  if (a.fullAddress === b.fullAddress) {
                      return 0;
                  }
                  // nulls sort after anything else
                  if (a.fullAddress === null || a.fullAddress === '') {
                      return index;
                  }
  
                  if (b.fullAddress === null || b.fullAddress === '') {
                      return -index;
                  }
  
                  // otherwise, if we're ascending, lowest sorts first
                  if (!sort.desc) {
                      return a.fullAddress < b.fullAddress ? -index : index;
                  }
                  return a.fullAddress < b.fullAddress ? index : -index;
                });
                break;
              case 'jobPosition':
                results = results.sort((a, b) =>{
                  // equal items sort equally
                  if (a.jobPosition === b.jobPosition) {
                      return 0;
                  }
                  // nulls sort after anything else
                  if (a.jobPosition === null) {
                      return index;
                  }
  
                  if (b.jobPosition === null) {
                      return -index;
                  }
                  // otherwise, if we're ascending, lowest sorts first
                  if (!sort.desc) {
                      return a.jobPosition < b.jobPosition ? -index : index;
                  }
                  return a.jobPosition < b.jobPosition ? index : -index;
                });
                break;
              default:
                break;
            }
          });
  
        return {
          data: results.slice(from, to),
          total: results.length,
          totalResults: totalResults,
        };
      }
    })
    .catch((error) => console.error(error));
}

export async function createContact(data) {
  const config = {
    method: 'post',
    url: `${API_URL}/contact`,
    withCredentials: true,
    data: data,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function updateContactApi(data, id, csrfToken) {
  const config = {
    method: 'patch',
    url: `${API_URL}/contact/${id}`,
    withCredentials: true,
    data: data,
    headers: {
      'csrf-token': csrfToken,
    },
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function deleteContact(id, csrfToken) {
  const config = {
    method: 'delete',
    url: `${API_URL}/contact/${id}`,
    withCredentials: true,
    headers: {
      'csrf-token': csrfToken,
    },
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function getContacts() {
  const config = {
    method: 'get',
    url: `${API_URL}/contact`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}
  
export async function getContact(id) {
  const config = {
    method: 'get',
    url: `${API_URL}/contact/${id}`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}
