import axios from 'axios';

const API_URL = process.env.REACT_APP_API_KEY || 'http://localhost:3002/api';

export async function getUser(id) {
  const config = {
    method: 'get',
    url: `${API_URL}/users/${id}`,
    withCredentials: true,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}
