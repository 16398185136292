import { useContext } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import UserContext from "../../utils/context/userContext";
import { roles } from "../../utils/data/role";

export function Note({ user, onDeleteNote, message, date }) {
    const loggedUser = useContext(UserContext);
    const isAllowedToDelete = loggedUser.id === user.id || loggedUser.level === roles.superadmin;
    return(
        <>
        <Card style={{ border:"0.5px solid black", borderRadius: "4px" }}>
            <Card.Header>
                <Row>
                    <Col md='auto' className="d-flex align-items-center">
                        User: { user.email }
                    </Col>
                    { (onDeleteNote && isAllowedToDelete) && 
                        (
                            <Col md='auto' className="ml-auto">
                                <Button
                                    href='#delete'
                                    className='btn-px-2 py-2 d-flex align-items-center font-weight-semibold line-height-1'
                                    variant='danger'
                                    onClick={ onDeleteNote }
                                    title='Delete note'
                                    >
                                    <i className='bx bx-trash text-4'></i>
                                </Button>
                            </Col>
                        )}
                </Row>
            </Card.Header>
            <Card.Body>
                { message }        
            </Card.Body>
            <Card.Footer className="d-flex justify-content-end">
                Created at: { date }
            </Card.Footer>
        </Card>
        <br />
        </>
    );
}