import { Button, Card, Col, Form, Row } from "react-bootstrap"
import { roles } from '../../utils/data/role';
import { useContext, useEffect, useMemo, useState } from "react";
import { storageCsrf } from "../../api/auth";
import { getUsers } from "../../api/user";
import Loader from "../../features/loader";
import Question from "./question";
import { updateLeadApi } from "../../api/electricUp";
import { toast } from "react-toastify";
import PNotify from "../../features/elements/p-notify";
import UserContext from "../../utils/context/userContext";
import { createQuestionAnswers, getQuestionsByCategoryName } from "../../api/questionsAndAnswers";
import { useNavigate } from "react-router-dom";


export default function QuestionsAndAnswers({ qaSource }) {
    const loggedUser = useContext(UserContext);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [responsibleId, setResponsibleId] = useState(qaSource?.responsible?.id);
    const [changeButtonIsHidden, setChangeButtonIsHidden] = useState(true);
    const [electricUpQuestions, setElectricUpQuestions] = useState([]);
    const allowedResponsibleEditors = useMemo(()=> {
        return [roles.admin, roles.superadmin]
    }, [])
    const allowedResponsibleViewers = useMemo(() => {
        return [roles.admin, roles.superadmin, roles.electricup, roles.sales]
    }, [])
    const answers = [];

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        if (allowedResponsibleViewers.includes(loggedUser.level)) {
            getUsers().then((result) => {
                setUsers(result.data.message);
            })
        }
        getQuestionsByCategoryName('ElectricUp').then((result) => {
            setElectricUpQuestions(result.data.message);
        })
        setLoading(false);
    }, [qaSource, setResponsibleId, loggedUser, allowedResponsibleViewers])

    function handleResponsibleOnChange(e) {
        setResponsibleId(e.target.value);
        setChangeButtonIsHidden(qaSource?.responsible?.id === e.target.value || !allowedResponsibleEditors.includes(loggedUser.level));
    }

    async function handleOnClick(e) {
        e.preventDefault();
        let csrfToken;
        if (sessionStorage.getItem('csrfToken')) {
            csrfToken = sessionStorage.getItem('csrfToken');
        } else {
            csrfToken = await storageCsrf();
        }
        electricUpQuestions.forEach((value, index) => {
            if(e.target[value.id].value){
                let dataObj = {};
                e.target[value.id].forEach((item) => {
                    if(item.checked) {
                        dataObj.id = item.dataset.idAnswer;
                    }
                })
                dataObj.questionId = value.id;
                dataObj.answer = e.target[value.id].value;
                dataObj.answerText = e.target[`obs_${value.id}`].value ?? '';
                answers.push(dataObj);
            }
        })
        let data = {
            _csrf: csrfToken,
            questionAnswersId: qaSource.questionAnswersId,
            answers: answers,
        };

        const leadQuestionAnswers = await createQuestionAnswers(qaSource.id, data)
        if (leadQuestionAnswers.status === 200 && leadQuestionAnswers.data.error === 0) {
            navigate(`${process.env.PUBLIC_URL}/dashboard/electric-up/leads`);
            return toast(
                <PNotify
                    title='Success'
                    icon='fas fa-check'
                    text={leadQuestionAnswers.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-success',
                }
            );
        } else {
            return toast(
                <PNotify
                    title='Error'
                    icon='fas fa-check'
                    text={leadQuestionAnswers.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-danger',
                }
            );
        }
    }

    async function handleResponsibleOnClick(e) {
        e.preventDefault();
        let csrfToken;
        if (sessionStorage.getItem('csrfToken')) {
            csrfToken = sessionStorage.getItem('csrfToken');
        } else {
            csrfToken = await storageCsrf();
        }
        let data = {
            _csrf: csrfToken,
            responsibleId: responsibleId
        }
        const result = await updateLeadApi(data, qaSource.id, csrfToken)
        if (result.status === 200 && result.data.error === 0) {
            return toast(
                <PNotify
                    title='Success'
                    icon='fas fa-check'
                    text={result.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-success',
                }
            );
        } else {
            return toast(
                <PNotify
                    title='Error'
                    icon='fas fa-check'
                    text={result.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-danger',
                }
            );
        }
    }

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Row className='p-2'>
                        <Col>
                            <Card>
                                <Card.Header>
                                    <Form.Group as={Row} className='align-items-center justify-content-center'>
                                        <Col
                                            as={Form.Label}
                                            lg={3}
                                            xl={2}
                                            className='control-label text-lg-right mb-lg-0'
                                        >
                                            Responsabil
                                        </Col>
                                        <Col lg={4} xl={3}>
                                            <Form.Control
                                                as='select'
                                                className='form-control-modern'
                                                name='responsibleId'
                                                value={responsibleId ?? ''}
                                                onChange={handleResponsibleOnChange}
                                                disabled={!allowedResponsibleEditors.includes(loggedUser.level)}
                                            >
                                                <option value="">Select Responsible</option>
                                                {users ?
                                                    users.map((user) => {
                                                        return (
                                                            <option key={user.id} value={user.id}>{user.lastName && user.firstName ? `${user.firstName} ${user.lastName}` : user.email}</option>
                                                        )
                                                    })
                                                    : ''
                                                }
                                            </Form.Control>
                                        </Col>
                                        {!changeButtonIsHidden ? (
                                            <Col lg={4} xl={3}>
                                                <Button
                                                    onClick={handleResponsibleOnClick}
                                                >
                                                    Change Responsible
                                                </Button>
                                            </Col>
                                        )
                                            : ('')
                                        }
                                    </Form.Group>
                                </Card.Header>
                                {electricUpQuestions ?
                                    <Question
                                        questionList={electricUpQuestions}
                                        qaSource={qaSource}
                                        handleSubmit={handleOnClick}
                                    >
                                    </Question>
                                    : ''
                                }
                            </Card>
                        </Col>
                    </Row>
                </>
            )}
        </>
    )
}