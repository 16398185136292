import axios from 'axios';
import { sortByAB } from '../utils/data/other';

const API_URL = process.env.REACT_APP_API_KEY || 'http://localhost:3002/api';

export async function getFilteredUsers(from = 0, to, filters, sortBy) {
  const config = {
    method: 'get',
    url: `${API_URL}/users`,
    withCredentials: true,
  };
  return axios(config)
    .then((response) => {
      let results = response.data.message;
      let totalResults = response.data.message.length;
      filters &&
        filters.forEach((filter) => {
          results = results.filter((product) => {
            if (filter.id !== 'name')
              return product[filter.id] === filter.value;
            return (
              (product.firstName + ' ' + product.lastName).search(
                new RegExp(filter.value, 'i')
              ) >= 0 ||
              product['email'].search(new RegExp(filter.value, 'i')) >= 0
            );
          });
        });

      sortBy &&
        sortBy.forEach((sort) => {
          let index = sort.desc ? -1 : 1;
          switch (sort.id) {
            case 'name':
              results = results.sort((a, b) => {
                let nameA = a.lastName + " " + a.firstName
                let nameB = b.lastName + " " + b.firstName
                return sortByAB(nameA, nameB, index, sort)
              });
              break;
            case 'email':
              results = results.sort((a, b) =>
                a.email < b.email ? -index : index
              );
              break;
            case 'role':
              results = results.sort((a, b) =>
                a.role.name < b.role.name ? -index : index
              );
              break;
            default:
              break;
          }
        });

      return {
        data: results.slice(from, to),
        total: results.length,
        totalResults: totalResults,
      };
    })
    .catch((error) => console.error(error));
}

export async function getUsers() {
  const config = {
    method: 'get',
    url: `${API_URL}/users`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}

export async function createAccount(data, csrfToken) {
  const config = {
    method: 'post',
    url: `${API_URL}/users`,
    withCredentials: true,
    data: data,
    headers: {
      'csrf-token': csrfToken,
    },
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function updateAccount(data, id, csrfToken) {
  const config = {
    method: 'patch',
    url: `${API_URL}/users/${id}`,
    withCredentials: true,
    data: data,
    headers: {
      'csrf-token': csrfToken,
    },
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function deleteAccount(id, csrfToken) {
  const config = {
    method: 'delete',
    url: `${API_URL}/users/${id}`,
    withCredentials: true,
    headers: {
      'csrf-token': csrfToken,
    },
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}
