import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { storageCsrf } from "../../api/auth";
import { updateLeadApi } from "../../api/electricUp";
import { getQuestionAnswersByQuestionIdAndQAId } from "../../api/questionsAndAnswers";
import { getStatuses } from '../../api/status'
import PNotify from "../../features/elements/p-notify";

export default function Question({ qaSource, questionList, handleSubmit }) {
    const [currentQuestionAnswerObj, setCurrentQuestionAnswerObj] = useState([]);
    const [statusesList, setStatusesList] = useState([]);
    const [statusId, setStatusId] = useState(qaSource.status?.id || []);
    const [isRequired, setIsRequired] = useState(true);
    const [neeligibilId, setNeeligibilId] = useState(null);

    useEffect(() => {
        function getListItems() {
            getQuestionAnswersByQuestionIdAndQAId(qaSource.questionAnswersId).then((result) => {
                if (result.status === 200 && result.data.error === 0) {
                    setCurrentQuestionAnswerObj(result.data.message);
                }
            });
            getStatuses().then((result) => {
                setStatusesList(result.data.message);
                setNeeligibilId(result.data.message.filter((status) => {
                    return status.name === 'neeligibil'
                })[0].id)
            });
        }
        if (!currentQuestionAnswerObj.length) {
            getListItems();
        }
    }, [questionList, qaSource.questionAnswersId, currentQuestionAnswerObj])

    function getCurrentQuestionAnswers(id) {
        if (currentQuestionAnswerObj) {
            return currentQuestionAnswerObj.filter((item) => item.questionId === id)[0];
        }
    }

    const handleAnswer = (questionId, answerValue) => {
        const currentAnswerIndex = currentQuestionAnswerObj.findIndex(answer => answer.questionId === questionId);

        if (currentAnswerIndex !== -1) {
            const updateAnswers = [...currentQuestionAnswerObj];
            updateAnswers[currentAnswerIndex].answer = answerValue;
            setCurrentQuestionAnswerObj(updateAnswers);
        }
    }

    async function handleStatusChange() {
        let csrfToken;
        if (sessionStorage.getItem('csrfToken')) {
            csrfToken = sessionStorage.getItem('csrfToken');
        } else {
            csrfToken = await storageCsrf();
        }

        const data = {
            _csrf: csrfToken,
            statusId: statusId,
        }

        updateLeadApi(data, qaSource.id, csrfToken).then((result) => {
            if (result.status === 200 && result.data.error === 0) {
                return toast(
                    <PNotify
                        title='Success'
                        icon='fas fa-check'
                        text={'CFE Status has been updated!'}
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-success',
                    }
                )
            } else {
                return toast(
                    <PNotify
                        title='Error'
                        icon='fas fa-check'
                        text={result.data.message}
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-danger',
                    }
                );
            }
        })

        // TODO change backend so you can save partial data for a lead
        if (statusId === neeligibilId) {
            setIsRequired(false);
        }
    }

    return (
        <>
            <Form onSubmit={handleSubmit}>
                {questionList.length ?
                    questionList.map((question, index) => {
                        let currQAnswer = getCurrentQuestionAnswers(question.id);
                        return (
                            <Card.Body key={++index}>
                                <Row className='p-3'>
                                    <Col className='col-md-12'>
                                        <Form.Group as={Row} className='align-items-center justify-content-between'>
                                            <Col
                                                as={Form.Label}
                                                lg={10}
                                                xl={10}
                                                className='control-label text-lg-left mb-lg-0'
                                            >
                                                {`${++index}. ${question.question}`}
                                            </Col>
                                            <Form.Group as={Row} className='align-items-center'>
                                                <Col
                                                    as={Form.Label}
                                                    lg={5}
                                                    xl={5}
                                                    className='control-label text-lg-right mb-lg-0'
                                                >
                                                    Da
                                                </Col>
                                                <Col lg={5} xl={5}>
                                                    <Form.Control
                                                        id='da'
                                                        type='radio'
                                                        name={`${question.id}`}
                                                        value='1'
                                                        data-id-answer={currQAnswer?.id ?? null}
                                                        checked={currQAnswer && currQAnswer.answer === true}
                                                        onChange={() => handleAnswer(question.id, true)}
                                                        required={isRequired}
                                                    ></Form.Control>
                                                </Col>
                                                <Col
                                                    as={Form.Label}
                                                    lg={5}
                                                    xl={5}
                                                    className='control-label text-lg-right mb-lg-0'
                                                >
                                                    Nu
                                                </Col>
                                                <Col lg={5} xl={5}>
                                                    <Form.Control
                                                        id='nu'
                                                        type='radio'
                                                        name={`${question.id}`}
                                                        value='0'
                                                        checked={currQAnswer && currQAnswer.answer === false}
                                                        onChange={() => handleAnswer(question.id, false)}
                                                        required={isRequired}
                                                    ></Form.Control>
                                                </Col>
                                            </Form.Group>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='pt-4 pb-4'>
                                    <Col>
                                        <Card className='card-modern card-big-info'>
                                            <Card.Body>
                                                <Row>
                                                    <Col lg='2-5' xl='1-5'>
                                                        <h2 className='card-big-info-title'>Obs. :</h2>
                                                        <p className='card-big-info-desc'>
                                                        </p>
                                                    </Col>
                                                    <Col className='p-0'>
                                                        <Form.Control
                                                            as='textarea'
                                                            className='form-control-modern h-100 w-100'
                                                            name={`obs_${question.id}`}
                                                            defaultValue={currQAnswer?.answerText ?? ''}
                                                        ></Form.Control>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Card.Body>
                        )
                    })
                    : ''
                }
                <Card.Footer>
                    <Row className="align-items-center justify-content-center">
                        <Col lg={4} xl={5}>
                            <Form.Group as={Row} className='align-items-center justify-content-start'>
                                <Col
                                    as={Form.Label}
                                    lg={2}
                                    xl={2}
                                    className='control-label text-lg-center mb-lg-0'
                                >
                                    Status
                                </Col>
                                <Col lg={5} xl={5}>
                                <Form.Control
                                    as='select'
                                    className='form-control-modern'
                                    name='responsibleId'
                                    value={statusId}
                                    onChange={(e) => setStatusId(e.target.value)}
                                >
                                    <option value="">Change status</option>
                                    {statusesList ?
                                        statusesList.map((status) => {
                                            return (
                                                <option key={status.id} value={status.id}>{status.name}</option>
                                            )
                                        })
                                        : ''
                                    }
                                </Form.Control>
                                </Col>
                                <Col lg={5} xl={4}>
                                    <Button
                                        onClick={handleStatusChange}
                                    >
                                        Change Status
                                    </Button>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col md='auto' className='ml-auto'>
                            <Button
                                type="submit"
                                className='btn-px-2 py-3 d-flex align-items-center font-weight-semibold line-height-1 mt-1'
                                variant='primary'
                                title='Shimba status in eligibil'
                            >
                                Submit &nbsp;<i className='bx bx-send text-4'></i>
                            </Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Form>
        </>
    )
}