import axios from 'axios';

const API_URL = process.env.REACT_APP_API_KEY || 'http://localhost:3002/api';

export async function getActivityLogsWithFilter(from = 0, to, filters, sortBy) {
    const config = {
      method: 'get',
      url: `${API_URL}/activity-logs`,
      withCredentials: true,
    };
    return axios(config)
      .then((response) => {
        let error = response.data.error
        if(!error){
          let results = response.data.message;
          filters &&
            filters.forEach((filter) => {
                results = results.filter((activityLog) => {
                  return (
                    activityLog['eventType'].search(new RegExp(filter.value, 'i')) >= 0 ||
                    activityLog['email'].search(new RegExp(filter.value, 'i')) >= 0 ||
                    activityLog['route'].search(new RegExp(filter.value, 'i')) >= 0 ||
                    activityLog['role'].search(new RegExp(filter.value, 'i')) >= 0 ||
                    activityLog['createdAt'].search(new RegExp(filter.value, 'i')) >= 0 
                  );
                });
            });
    
          sortBy &&
            sortBy.forEach((sort) => {
              let index = sort.desc ? -1 : 1;
              switch (sort.id) {
                case 'eventType':
                  results = results.sort((a, b) =>
                    a.eventType < b.eventType ? -index : index
                  );
                  break;
                case 'route':
                  results = results.sort((a, b) =>
                    a.route < b.route ? -index : index
                  );
                  break;
                case 'email':
                  results = results.sort((a, b) =>
                    a.email < b.email ? -index : index
                  );
                  break;
                case 'role':
                  results = results.sort((a, b) =>
                  a.role < b.role ? -index : index
                  );
                  break;
                case 'createdAt':
                  results = results.sort((a, b) =>
                    a.createdAt < b.createdAt ? -index : index
                  );
                  break;
                case 'status':
                  results = results.sort((a, b) =>
                    a.status < b.status ? -index : index
                  );
                  break;
                default:
                  break;
              }
            });
    
          return {
            data: results.slice(from, to),
            total: results.length,
          };
        }
      })
      .catch((error) => console.error(error));
}