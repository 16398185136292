import axios from 'axios';

const API_URL = process.env.REACT_APP_API_KEY || 'http://localhost:3002/api';

export async function getRolesApi(setRoles) {
  const result = await getRoles();
  if (result.status === 200) {
    setRoles(result.data.message);
  }
}

export async function getRolesWithFilter(from = 0, to, filters, sortBy) {
  const config = {
    method: 'get',
    url: `${API_URL}/data/role`,
    withCredentials: true,
  };
  return axios(config)
    .then((response) => {
      let error = response.data.error
      if(!error){
        let results = response.data.message;
        let totalResults = response.data.message.length;
        filters &&
          filters.forEach((filter) => {
            results = results.filter((product) => {
              if (filter.id !== 'name')
                return product[filter.id] === filter.value;
              return (
                (product.first_name + ' ' + product.last_name).search(
                  new RegExp(filter.value, 'i')
                ) >= 0 ||
                product['name'].search(new RegExp(filter.value, 'i')) >= 0
              );
            });
          });

        sortBy &&
          sortBy.forEach((sort) => {
            let index = sort.desc ? -1 : 1;
            switch (sort.id) {
              case 'level':
                results = results.sort((a, b) =>
                  a.level < b.level ? -index : index
                );
                break;
              case 'name':
                results = results.sort((a, b) =>
                  a.name < b.name ? -index : index
                );
                break;
              default:
                break;
            }
          });

        return {
          data: results.slice(from, to),
          total: results.length,
          totalResults: totalResults,
        };
      }
    })
    .catch((error) => console.error(error));
}

export async function createRole(data) {
  const config = {
    method: 'post',
    url: `${API_URL}/data/role`,
    withCredentials: true,
    data: data,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function updateRoleApi(data, id, csrfToken) {
  const config = {
    method: 'patch',
    url: `${API_URL}/data/role/${id}`,
    withCredentials: true,
    data: data,
    headers: {
      'csrf-token': csrfToken,
    },
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function deleteRole(id, csrfToken) {
  const config = {
    method: 'delete',
    url: `${API_URL}/data/role/${id}`,
    withCredentials: true,
    headers: {
      'csrf-token': csrfToken,
    },
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function getRoles() {
  const config = {
    method: 'get',
    url: `${API_URL}/data/role`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}

export async function getRole(id) {
  const config = {
    method: 'get',
    url: `${API_URL}/data/role/${id}`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}