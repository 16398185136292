import React, { useEffect, useState } from 'react';
import Modal from "react-modal";
import { Row, Col, Card, Button, Form } from 'react-bootstrap';

const modalStyles = {
    overlay: {
        background: 'rgba(11, 11, 11, .8)',
        zIndex: 9999
    },
    content: {
        left: '50%',
        top: '50%',
        bottom: 'auto',
        right: 'auto',
        outline: 'none',
        width: '80%',
        padding: 0,
        transform: 'translate(-50%, -50%)'
    }
};
Modal.setAppElement('#app');


export default function QuestionCategoryDetailModal({ isOpen, onClose, category }) {
    const [newCategoryName, setNewCategoryName] = useState([]);    

    function closeModal(result) {
        const categoryUpdateData = {
            name:newCategoryName,
        }
        onClose(result, categoryUpdateData, 'category');
    }

    useEffect((e) => {
        setNewCategoryName(category?.name ?? '');
    },[category]);

    return (
        <Modal
            isOpen={isOpen}
            style={modalStyles}
            onRequestClose={() => closeModal(false)}
        >
            <Card>
                <Card.Header>
                    <Card.Title>Edit Question</Card.Title>
                </Card.Header>
                <Card.Body>
                    <div className="modal-wrapper">
                        <Row>
                            <Col lg={4} xl={4} className='pl-5'>
                                <h2 className='card-big-info-title'>Category Details</h2>
                                <p className='card-big-info-desc'>
                                    Add here the Category info.
                                </p>
                            </Col>
                            <Col lg={5} xl={7}>
                                <Form.Group as={Row} className='align-items-center'>
                                    <Col
                                        as={Form.Label}
                                        lg={4}
                                        xl={2}
                                        className='control-label text-lg-right mb-lg-0'
                                    >
                                        Name
                                    </Col>
                                    <Col lg={8} xl={8}>
                                        <Form.Control
                                            type='text'
                                            className='form-control-modern'
                                            rows={ 5 }
                                            maxLength='2500'
                                            name='question'
                                            defaultValue={category?.name ?? ''}
                                            onChange={(e) => {
                                                setNewCategoryName(e.target.value);
                                            }}
                                            required
                                        ></Form.Control>
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col md={12} className="text-right">
                            <Button
                                className="modal-confirm mr-2"
                                variant="primary"
                                onClick={() => closeModal(true)}
                            >{category ? 'Update' : 'Add'}</Button>
                            <Button
                                className="modal-dismiss"
                                variant="default"
                                onClick={() => closeModal(false)}
                            >Cancel</Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Modal>
    )
}