import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Button, InputGroup, Tabs, Tab } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Breadcrumb from '../../common/breadcrumb';
import PtTable from '../../../features/elements/table';
import PNotify from '../../../features/elements/p-notify';
import { storageCsrf } from '../../../api/auth';
import { createQuestion, createQuestionCategory, deleteQuestion, deleteQuestionCategory, getQuestionCategories, getQuestionCategory, getQuestionsApi, getQuestionsOrCategoriesWithFilter, getQuestionsWithFilter, updateQuestion, updateQuestionCategory } from '../../../api/questionsAndAnswers';
import QuestionDetailModal from './modals/question-detail-modal';
import QuestionCategoryDetailModal from './modals/question-category-detail-modal';

export default function QuestionsList() {
  const [tableKey, setTableKey] = useState('');
  const [loading, setLoading] = useState(true);
  const [ajax, setAjax] = useState({
    data: [],
    total: 0,
  });

  // Filter Variables
  const [search, setSearch] = useState('');

  const [selectAll, setSelectAll] = useState(false);
  const [tableRef, setTableRef] = useState(null);
  const [selected, setSelected] = useState([]);
  const [bulk, setBulk] = useState('');
  // Questions
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [questionCategories, setQuestionCategories] = useState([]);

  // Columns
  const questionCategoriesColumns = [
    {
        Header: 'Nume',
        accessor: 'name',
        sortable: true,
    },
    {
      Header: 'Actions',
      accessor: 'id',
      className: 'actions',
      headerClassName: 'justify-content-center',
      width: 100,
      Cell: (row) => (
        <>
          <a
            href='#edit'
            className='on-default edit-row mr-2'
            onClick={(e) => editItemModal(row.value, 'categories')}
          >
            <i className='fas fa-pencil-alt'></i>
          </a>
          <a
            href='#del'
            className='on-default remove-row'
            onClick={(e) => deleteRow(e, row.value, 'category')}
          >
            <i className='far fa-trash-alt'></i>
          </a>
        </>
      ),
    },
  ];
  
  const questionColumns = [
    {
        Header: 'Intrebare',
        accessor: 'question',
        sortable: true,
    },
    {
        Header: 'Categorie de intrebare',
        id: 'questionCategory',
        accessor: (d) => d?.category?.name,
        sortable: true,
    },
    {
      Header: 'Numar de ordine',
      accessor: 'order',
      sortable: true,
    },
    {
      Header: 'Actions',
      accessor: 'id',
      className: 'actions',
      headerClassName: 'justify-content-center',
      width: 100,
      Cell: (row) => (
        <>
          <a
            href='#edit'
            className='on-default edit-row mr-2'
            onClick={(e) => editItemModal(row.value, 'questions')}
          >
            <i className='fas fa-pencil-alt'></i>
          </a>
          <a
            href='#del'
            className='on-default remove-row'
            onClick={(e) => deleteRow(e, row.value, 'question')}
          >
            <i className='far fa-trash-alt'></i>
          </a>
        </>
      ),
    },
  ];

  function fetchQuestionCategories() {
    getQuestionCategories().then((result) => {
      setQuestionCategories(result.data.error ? null : result.data.message);
    })
  }

  useEffect(() => {
    setSelected((selected) =>
      selected.map((item) => {
        return {
          ...item,
          selected: selectAll,
        };
      })
    );
    fetchQuestionCategories();
    setLoading(false);
  document.querySelector('body').classList.add('loaded');
  }, [selectAll]);

  function isSelected(key) {
    return selected.find((item) => item.id === key && item.selected);
  }

  function onSelectChange(e, value, row) {
    setSelected(
      selected.map((item) => {
        if (item.id === row.id) {
          return {
            ...item,
            selected: !item.selected,
          };
        }
        return item;
      })
    );
  }

  async function deleteRow(e, index, type) {
    e.preventDefault();
    if (window.confirm('Are you sure you want to delete this data?')) {
      let csrfToken;
      if (sessionStorage.getItem('csrfToken')) {
        csrfToken = sessionStorage.getItem('csrfToken');
      } else {
        csrfToken = await storageCsrf();
      }
      const deleteItem = type === 'question' ? await deleteQuestion(index, csrfToken) : await deleteQuestionCategory(index, csrfToken);
      if(deleteItem.status === 200) {
        setAjax({
          ...ajax,
          data: ajax.data.filter((item) => item.id !== index)
        });
        return toast(
          <PNotify
            title='Success'
            icon='fas fa-check'
            text={deleteItem.data.message}
          />,
          {
            containerId: 'default',
            className: 'notification-success',
          }
        );
      } else {
        return toast(
          <PNotify
            title='Error'
            icon='fas fa-check'
            text={deleteItem.data.message}
          />,
          {
            containerId: 'default',
            className: 'notification-danger',
          }
        );
      }
    }
  }

  async function bulkAction(e, type) {
    e.preventDefault();
    if (!bulk) {
      return toast(
        <PNotify
          title='Warning'
          icon='fas fa-exclamation'
          text='Please choose one of actions.'
        />,
        {
          containerId: 'default',
          className: 'notification-warning',
        }
      );
    }
    if (bulk === 'delete') {
      if (!selected.find((item) => item.selected)) {
        return toast(
          <PNotify
            title='Warning'
            icon='fas fa-exclamation'
            text='Choose at least one item.'
          />,
          {
            containerId: 'default',
            className: 'notification-warning',
          }
        );
      }

      let stringId = '';
      const bulkDeleteId = selected.filter((item) => item.selected);
      var bulkLength = bulkDeleteId.length;
      bulkDeleteId.forEach((item, index) => {
        if (index + 1 === bulkLength) {
          stringId += item.id;
        } else {
          stringId += item.id + '&';
        }
      });
      let csrfToken;
      if (sessionStorage.getItem('csrfToken')) {
        csrfToken = sessionStorage.getItem('csrfToken');
      } else {
        csrfToken = await storageCsrf();
      }
      const deleteItems = type === 'questions' ? await deleteQuestion(stringId, csrfToken) : await deleteQuestionCategory(stringId, csrfToken);
      if (deleteItems.status === 200) {
        setAjax({
          ...ajax,
          data: ajax.data.filter((media) =>
            selected.find((item) => item.id === media.id && !item.selected)
          ),
          totalRecords: ajax.totalRecords - bulkLength,
          totalFilteredRecords: ajax.totalFilteredRecords - bulkLength,
        });
        return toast(
          <PNotify
            title='Success'
            icon='fas fa-check'
            text={deleteItems.data.message}
          />,
          {
            containerId: 'default',
            className: 'notification-success',
          }
        );
      } else {
        return toast(
          <PNotify
            title='Error'
            icon='fas fa-check'
            text={deleteItems.data.message}
          />,
          {
            containerId: 'default',
            className: 'notification-danger',
          }
        );
      }
    }
  }

  function searchContacts(e) {
    e.preventDefault();
    tableRef.current.wrappedInstance.filterColumn({ id: 'search' }, search);
  }

  async function fetchData(state) {
    let filtered = [...state.filtered];
    setLoading(true);
    await getQuestionsOrCategoriesWithFilter(
      state.page * state.pageSize,
      (state.page + 1) * state.pageSize,
      filtered,
      state.sorted,
      state.id === 'categoriesTable' ? 'questionCategories' : 'questions',
    )
    .then((results) => {
      if(results?.data){
        setAjax({
          data: results.data,
          total:
            parseInt(results.total / state.pageSize) +
            !!(results.total % state.pageSize),
          totalRecords: results.totalResults,
          totalFilteredRecords: results.total,
          isFiltered: filtered.length ? true : false,
        });
        setSelected(
          results.data.map((media) => {
            return {
              id: media.id,
              selected: false,
            };
          })
        );
        setSelectAll(false);
      }
      setLoading(false);
    });
  }

  function addItemModal(e) {
    e.preventDefault();
    setIsOpenModal(true)
  }

  async function editItemModal(index, type) {
    if(type === 'questions') {
      await getQuestionsApi(index).then((result) => {
        setSelectedItem(result.data.message)
        setIsOpenModal(true)
      });
    } else {
      await getQuestionCategory(index).then((result) => {
        setSelectedItem(result.data.message)
        setIsOpenModal(true)
      });
    }
    
  }

  async function addEditModalConfirm(result, data, type) {
    if(result) {
      let itemResult;
      let csrfToken;
      if (sessionStorage.getItem('csrfToken')) {
        csrfToken = sessionStorage.getItem('csrfToken');
      } else {
        csrfToken = await storageCsrf();
      }
      data._csrf = csrfToken;
      if(type === 'question'){
        itemResult = 
          selectedItem
          ? await updateQuestion(selectedItem.id, data) 
          : await createQuestion(data);
      } else if(type === 'category'){
        itemResult = 
          selectedItem
          ? await updateQuestionCategory(selectedItem.id, data) 
          : await createQuestionCategory(data);
          fetchQuestionCategories();
      }
      setTableKey(Math.random().toString());
      setIsOpenModal(false);
      if (itemResult.status === 200 && itemResult.data.error === 0) {
        return toast(
          <PNotify
            title='Success'
            icon='fas fa-check'
            text={itemResult.data.message}
          />,
          {
            containerId: 'default',
            className: 'notification-success',
          }
        );
      } else {
        return toast(
          <PNotify
            title='Error'
            icon='fas fa-check'
            text={itemResult.data.message}
          />,
          {
            containerId: 'default',
            className: 'notification-danger',
          }
        );
      }
    }
    setSelectedItem(null);
    setIsOpenModal(false);
  }

  return (
    <>
      <Tabs defaultActiveKey={'questions'} mountOnEnter={true} unmountOnExit={true} variant={'pills'} className="mb-3">
        <Tab eventKey={'questions'} title={'Questions'}>
          <Breadcrumb
            current={'Questions'}
            paths={[
              {
                name: 'Dashboard',
                url: '/dashboard',
              },
              {
                name: 'Questions',
                url: '/dashboard/questions',
              },
            ]}
          />
          <Row>
            <Col>
              <Form method='GET' action='#' onSubmit={searchContacts}>
                <Card className='card-modern'>
                  <Card.Body>
                    <div className='datatables-header-footer-wrapper'>
                      <div className='datatable-header'>
                        <Row className='align-items-lg-center mb-3'>
                          <Col xl='auto' className='mb-2 mt-1 mb-xl-0'>
                            <Button
                              className='font-weight-semibold'
                              variant='primary'
                              size='md'
                              onClick={addItemModal}
                            >
                              + Add Question
                            </Button>
                          </Col>
                          <Col
                            lg='auto'
                            className='mb-2 mb-lg-0 ml-xl-auto pl-xl-1'
                          ></Col>
                          <Col className='col-auto pl-lg-1'>
                            <div className='search search-style-1 mx-lg-auto w-auto'>
                              <InputGroup>
                                <Form.Control
                                  type='text'
                                  className='search-term'
                                  placeholder='Search'
                                  value={search}
                                  onChange={(e) => setSearch(e.target.value)}
                                />
                                <InputGroup.Append>
                                  <Button variant='default' type='submit'>
                                    <i className='bx bx-search'></i>
                                  </Button>
                                </InputGroup.Append>
                              </InputGroup>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <PtTable
                        className='table table-ensys-simple -striped mb-0'
                        id='questionsTable'
                        key={tableKey}
                        data={ajax.data}
                        loading={loading}
                        columns={questionColumns}
                        pages={ajax.total}
                        pageSize={10}
                        manual
                        onFetchData={fetchData}
                        selectAll={selectAll}
                        toggleAll={() => setSelectAll(!selectAll)}
                        isSelected={(key) => isSelected(key)}
                        toggleSelection={onSelectChange}
                        onChangeRef={(ref) => setTableRef(ref)}
                        totalRecords={ajax.totalRecords}
                        totalFilteredRecords={ajax.totalFilteredRecords}
                        isFiltered={ajax.isFiltered}
                      />

                      <div className='datatable-footer'>
                        <Row className='align-items-center justify-content-between mt-3'>
                          <Col md='auto' className='mb-3 mb-lg-0'>
                            <div className='d-flex'>
                              <Form.Control
                                as='select'
                                className='select-style-1 bulk-action w-auto mr-3'
                                value={bulk}
                                onChange={(e) => setBulk(e.target.value)}
                                style={{ minWidth: '120px' }}
                              >
                                <option value=''>Bulk Actions</option>
                                <option value='delete'>Delete</option>
                              </Form.Control>
                              <Button
                                href='#bulk-action'
                                className='bulk-action-apply border font-weight-semibold text-color-dark text-3'
                                variant='light'
                                onClick={(e) => bulkAction(e,'questions')}
                              >
                                Apply
                              </Button>
                            </div>
                          </Col>
                          <Col lg='auto' className='mb-3 mb-lg-0'>
                            <div className='pagination-wrapper d-flex justify-content-lg-end'></div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
          <QuestionDetailModal isOpen={isOpenModal} onClose={addEditModalConfirm} question={selectedItem} categories={questionCategories} />
        </Tab>
        <Tab eventKey={'categories'} title={'Categories'} mountOnEnter={true} unmountOnExit={true}>
        <Breadcrumb
            current={'Question Categories'}
            paths={[
              {
                name: 'Dashboard',
                url: '/dashboard',
              },
              {
                name: 'Question Categories',
                url: '/dashboard/categories',
              },
            ]}
          />
          <Row>
              <Col>
                <Form method='GET' action='#' onSubmit={searchContacts}>
                  <Card className='card-modern'>
                    <Card.Body>
                      <div className='datatables-header-footer-wrapper'>
                        <div className='datatable-header'>
                          <Row className='align-items-lg-center mb-3'>
                            <Col xl='auto' className='mb-2 mt-1 mb-xl-0'>
                              <Button
                                className='font-weight-semibold'
                                variant='primary'
                                size='md'
                                onClick={addItemModal}
                              >
                                + Add Category
                              </Button>
                            </Col>
                            <Col
                              lg='auto'
                              className='mb-2 mb-lg-0 ml-xl-auto pl-xl-1'
                            ></Col>
                            <Col className='col-auto pl-lg-1'>
                              <div className='search search-style-1 mx-lg-auto w-auto'>
                                <InputGroup>
                                  <Form.Control
                                    type='text'
                                    className='search-term'
                                    placeholder='Search'
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                  />
                                  <InputGroup.Append>
                                    <Button variant='default' type='submit'>
                                      <i className='bx bx-search'></i>
                                    </Button>
                                  </InputGroup.Append>
                                </InputGroup>
                              </div>
                            </Col>
                          </Row>
                        </div>

                        <PtTable
                          className='table table-ensys-simple -striped mb-0'
                          id='categoriesTable'
                          key={tableKey}
                          data={ajax.data}
                          loading={loading}
                          columns={questionCategoriesColumns}
                          pages={ajax.total}
                          pageSize={10}
                          manual
                          onFetchData={fetchData}
                          selectAll={selectAll}
                          toggleAll={() => setSelectAll(!selectAll)}
                          isSelected={(key) => isSelected(key)}
                          toggleSelection={onSelectChange}
                          onChangeRef={(ref) => setTableRef(ref)}
                          totalRecords={ajax.totalRecords}
                          totalFilteredRecords={ajax.totalFilteredRecords}
                          isFiltered={ajax.isFiltered}
                        />

                        <div className='datatable-footer'>
                          <Row className='align-items-center justify-content-between mt-3'>
                            <Col md='auto' className='mb-3 mb-lg-0'>
                              <div className='d-flex'>
                                <Form.Control
                                  as='select'
                                  className='select-style-1 bulk-action w-auto mr-3'
                                  value={bulk}
                                  onChange={(e) => setBulk(e.target.value)}
                                  style={{ minWidth: '120px' }}
                                >
                                  <option value=''>Bulk Actions</option>
                                  <option value='delete'>Delete</option>
                                </Form.Control>
                                <Button
                                  href='#bulk-action'
                                  className='bulk-action-apply border font-weight-semibold text-color-dark text-3'
                                  variant='light'
                                  onClick={(e) => bulkAction(e,'categories')}
                                >
                                  Apply
                                </Button>
                              </div>
                            </Col>
                            <Col lg='auto' className='mb-3 mb-lg-0'>
                              <div className='pagination-wrapper d-flex justify-content-lg-end'></div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
            <QuestionCategoryDetailModal isOpen={isOpenModal} onClose={addEditModalConfirm} category={selectedItem}/>
        </Tab>
      </Tabs>
    </>
  );
}
