import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Breadcrumb from '../../common/breadcrumb';
import DeleteConfirmModal from '../../../features/modals/delete-confirm-modal';
import Loader from '../../../features/loader';
import PNotify from '../../../features/elements/p-notify';

import { storageCsrf } from '../../../api/auth';
import { deleteNomenclature, getNomenclature, updateNomenclatureApi } from '../../../api/nomenclature';

export default function NomenclatureDetail() {
  const [nomenclature, setNomenclature] = useState(null);
  const [nomenclatureOld, setNomenclatureOld] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getNomenclature(id).then((result) => {
      setNomenclature(result.data.message);
      setNomenclatureOld(result.data.message);
      setLoading(false);
    });
    document.querySelector('body').classList.add('loaded');
  }, [id, setNomenclature, setNomenclatureOld]);

  async function updateNomenclature(e) {
    e.preventDefault();
    let csrfToken;
    if (sessionStorage.getItem('csrfToken')) {
      csrfToken = sessionStorage.getItem('csrfToken');
    } else {
      csrfToken = await storageCsrf();
    }
    let data = {
      _csrf: csrfToken,
    };
    if (nomenclatureOld.name !== e.target.name.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) {
      data.name = e.target.name.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    }
    if (nomenclatureOld.tableName !== e.target.tableName.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) {
      data.tableName = e.target.tableName.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    }
    const result = await updateNomenclatureApi(data, id, csrfToken);
    if (result.status === 200 && result.data.error === 0) {
      navigate(`${process.env.PUBLIC_URL}/dashboard/nomenclature`);
      return toast(
        <PNotify
          title='Success'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-success',
        }
      );
    } else {
      return toast(
        <PNotify
          title='Error'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-danger',
        }
      );
    }
  }

  function deleteNomenclatureApi(e) {
    e.preventDefault();
    setOpenModal(true);
  }

  async function deleteConfirm(result) {
    setOpenModal(false);
    if (result) {
      navigate(`${process.env.PUBLIC_URL}/dashboard/nomenclature`);
      let csrfToken;
      if (sessionStorage.getItem('csrfToken')) {
        csrfToken = sessionStorage.getItem('csrfToken');
      } else {
        csrfToken = await storageCsrf();
      }
      const checker = await deleteNomenclature(id, csrfToken);
      if (checker.status === 200 && checker.data.error === 0) {
        navigate(`${process.env.PUBLIC_URL}/dashboard/nomenclature`);
        return toast(
          <PNotify
            title='Success'
            icon='fas fa-check'
            text={checker.data.message}
          />,
          {
            containerId: 'default',
            className: 'notification-success',
          }
        );
      } else {
        return toast(
          <PNotify
            title='Error'
            icon='fas fa-check'
            text={checker.data.message}
          />,
          {
            containerId: 'default',
            className: 'notification-danger',
          }
        );
      }
    }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Breadcrumb
            current='Edit Entry'
            paths={[
              {
                name: 'Dashboard',
                url: '/',
              },
              {
                name: 'Edit Entry',
                url: `#`,
              },
            ]}
          />

          <Form
            className='ecommerce-form'
            action='#'
            method='post'
            onSubmit={updateNomenclature}
          >
            <Row>
              <Col>
                <Card className='card-modern card-big-info'>
                  <Card.Body>
                    <Row>
                      <Col lg='2-5' xl='1-5'>
                        <i className='card-big-info-icon bx bx-user-circle'></i>
                        <h2 className='card-big-info-title'>Nomenclature Info</h2>
                        <p className='card-big-info-desc'>
                          Add here the Nomenclature info.
                        </p>
                      </Col>
                      <Col lg='3-5' xl='4-5'>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Name
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type='text'
                              maxLength='30'
                              className='form-control-modern'
                              name='name'
                              value={nomenclature.name}
                              onChange={(e) => {
                                setNomenclature({ ...nomenclature, name: e.target.value });
                              }}
                              required
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Table Name
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type='text'
                              maxLength='30'
                              className='form-control-modern'
                              name='tableName'
                              value={nomenclature.tableName}
                              onChange={(e) => {
                                setNomenclature({ ...nomenclature, tableName: e.target.value });
                              }}
                              required
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className='action-buttons'>
              <Col md='auto' className='col-6'>
                <Button
                  type='submit'
                  className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
                  variant='primary'
                >
                  <i className='bx bx-save text-4 mr-2'></i> Save Entry
                </Button>
              </Col>
              <Col md='auto' className='col-6 px-md-0 mt-0'>
                <Button
                  as={Link}
                  to={`${process.env.PUBLIC_URL}/dashboard/nomenclature`}
                  className='btn-px-4 py-3 border font-weight-semibold text-color-dark line-height-1 d-flex h-100 align-items-center'
                  variant='light'
                >
                  Back
                </Button>
              </Col>
              <Col md='auto' className='col-6 ml-md-auto mt-3 mt-md-0'>
                <Button
                  href='#delete'
                  className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
                  variant='danger'
                  onClick={deleteNomenclatureApi}
                >
                  <i className='bx bx-trash text-4 mr-2'></i> Delete Entry
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}

      <DeleteConfirmModal isOpen={openModal} onClose={deleteConfirm} />
    </>
  );
}
