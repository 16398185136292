
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout, storageCsrf } from '../../../api/auth';

export default function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    async function logoutJWT() {
        let csrfToken;
        if (sessionStorage.getItem('csrfToken')) {
          csrfToken = sessionStorage.getItem('csrfToken');
        } else {
          csrfToken = await storageCsrf();
        }

        const result = await logout(csrfToken);
        if (result.status === 200) {
          localStorage.setItem('isLoggedIn', 'false');
          navigate(`${process.env.PUBLIC_URL}/login/`);
        }
    }
    logoutJWT();
    document.querySelector('body').classList.add('loaded');
  }, [navigate]);

  return null;
}
