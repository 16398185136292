import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Breadcrumb from '../../common/breadcrumb';
import PNotify from '../../../features/elements/p-notify';
import { storageCsrf } from '../../../api/auth';
import { createStatusApi } from '../../../api/status';

export default function StatusCreate() {
    async function createStatus(e) {
        e.preventDefault();
        let csrfToken;
        if (sessionStorage.getItem('csrfToken')) {
            csrfToken = sessionStorage.getItem('csrfToken');
        } else {
            csrfToken = await storageCsrf();
        }
        let data = {
            name: e.target.name.value,
            tableName: e.target.tableName.value,
            icon: e.target.icon.value,
            order: e.target.order.value,
            _csrf: csrfToken,
        };

        const result = await createStatusApi(data, csrfToken);
        if (result.status === 200 && result.data.error === 0) {
            document.getElementById('myForm').reset();
            return toast(
                <PNotify
                    title='Success'
                    icon='fas fa-check'
                    text={result.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-success',
                }
            );
        } else {
            return toast(
                <PNotify
                    title='Error'
                    icon='fas fa-check'
                    text={result.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-danger',
                }
            );
        }
    }

    useEffect(() => {
        document.querySelector('body').classList.add('loaded');
    });

    return (
        <>
            <Breadcrumb
                current='Edit Status'
                paths={[
                    {
                        name: 'Home',
                        url: '/',
                    },
                    {
                        name: 'statuses',
                        url: `/dashboard/status`,
                    },
                    {
                        name: 'edit',
                        url: `/edit`,
                    },
                ]}
            />

            <Form
                className='ecommerce-form'
                action='#'
                method='post'
                onSubmit={createStatus}
                id='myForm'
            >
                <Row>
                    <Col>
                        <Card className='card-modern card-big-info'>
                            <Card.Body>
                                <Row>
                                    <Col lg='2-5' xl='1-5'>
                                        <i className='card-big-info-icon bx bx-user-circle'></i>
                                        <h2 className='card-big-info-title'>Status Info</h2>
                                        <p className='card-big-info-desc'>
                                            Add here the status info.
                                        </p>
                                    </Col>
                                    <Col lg='3-5' xl='4-5'>
                                        <Form.Group as={Row} className='align-items-center'>
                                            <Col
                                                as={Form.Label}
                                                lg={5}
                                                xl={3}
                                                className='control-label text-lg-right mb-lg-0'
                                            >
                                                Status Name
                                            </Col>
                                            <Col lg={7} xl={6}>
                                                <Form.Control
                                                    type='text'
                                                    maxLength='30'
                                                    className='form-control-modern'
                                                    name='name'
                                                    required
                                                ></Form.Control>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='align-items-center'>
                                            <Col
                                                as={Form.Label}
                                                lg={5}
                                                xl={3}
                                                className='control-label text-lg-right mb-lg-0'
                                            >
                                                Table name
                                            </Col>
                                            <Col lg={7} xl={6}>
                                                <Form.Control
                                                    type='text'
                                                    maxLength='30'
                                                    className='form-control-modern'
                                                    name='tableName'
                                                    required
                                                ></Form.Control>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='align-items-center'>
                                            <Col
                                                as={Form.Label}
                                                lg={5}
                                                xl={3}
                                                className='control-label text-lg-right mb-lg-0'
                                            >
                                                Icon
                                            </Col>
                                            <Col lg={7} xl={6}>
                                                <Form.Control
                                                    type='text'
                                                    maxLength='30'
                                                    className='form-control-modern'
                                                    name='icon'
                                                ></Form.Control>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='align-items-center'>
                                            <Col
                                                as={Form.Label}
                                                lg={5}
                                                xl={3}
                                                className='control-label text-lg-right mb-lg-0'
                                            >
                                                Order
                                            </Col>
                                            <Col lg={7} xl={6}>
                                                <Form.Control
                                                    type='number'
                                                    maxLength='30'
                                                    className='form-control-modern'
                                                    name='order'
                                                    required
                                                ></Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='action-buttons'>
                    <Col md='auto' className='col-6'>
                        <Button
                            type='submit'
                            className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
                            variant='primary'
                        >
                            <i className='bx bx-save text-4 mr-2'></i> Save Status
                        </Button>
                    </Col>
                    <Col md='auto' className='col-6 px-md-0 mt-0'>
                        <Button
                            as={Link}
                            to={`${process.env.PUBLIC_URL}/dashboard/status`}
                            className='btn-px-4 py-3 border font-weight-semibold text-color-dark line-height-1 d-flex h-100 align-items-center'
                            variant='light'
                        >
                            Back
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}
