import axios from 'axios';

const API_URL = process.env.REACT_APP_API_KEY || 'http://localhost:3002/api';

export async function getCategoriesApi(setCategories) {
  const result = await getCategories();
  if (result.status === 200) {
    setCategories(result.data.message);
  }
}

export async function getCategories() {
  const config = {
    method: 'get',
    url: `${API_URL}/project-category`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}