import { Button, Card, Col, Form, Row } from "react-bootstrap"
import { getLoggedUser, storageCsrf } from "../../api/auth";
import { Note } from "./note"
import { createNoteApi, deleteNoteApi, getNotesApi } from "../../api/note"
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PNotify from "../../features/elements/p-notify";

/**
 * Component description
 *
 * @param {object} noteSource - Obiectul principal care are relatia cu Notes pentru a putea extrage noteId
 * @param {boolean} disableDelete - Parametru optional pentru eliminarea butonului de stergere a notitelor
 */

export default function Notes({ noteSource, disableDelete }){
    const [notes, setNotes] = useState([]);
    const noteId = noteSource.noteId;
    
    useEffect(() => {
        if(!notes || notes.length === 0){
            getNotes(noteId);
        }
        document.querySelector('body').classList.add('loaded');
    }, [noteId, setNotes, notes]);
    
    async function getNotes(noteId)
    {
        await getNotesApi(noteId).then((result) => {
            if(typeof result === 'string'){
                setNotes(true);
            } else {
                setNotes(result);
            }
        });
    }

    async function addNotes(e){
        e.preventDefault();
        let message = e.target.note.value;
        let csrfToken;
        if (sessionStorage.getItem('csrfToken')) {
            csrfToken = sessionStorage.getItem('csrfToken');
          } else {
            csrfToken = await storageCsrf();
          }
        const getUser = await getLoggedUser();
        let data = {
            noteId: noteId,
            message: message,
            userId: getUser.data.id,
            _csrf: csrfToken,
        }

        const result = await createNoteApi(data, csrfToken);
        if (result.status && result.status === 200 && result.data.error === 0) {
            getNotes(noteId);
            return toast(
              <PNotify
                title='Success'
                icon='fas fa-check'
                text={result.data.message}
              />,
              {
                containerId: 'default',
                className: 'notification-success',
              }
            );
          } else {
            return toast(
              <PNotify
                title='Error'
                icon='fas fa-check'
                text={result.data.message}
              />,
              {
                containerId: 'default',
                className: 'notification-danger',
              }
            );
          }
    }

    async function handleDeleteNote(id) {
        if (window.confirm('Are you sure you want to delete this note?')) {
            let csrfToken;
            if (sessionStorage.getItem('csrfToken')) {
              csrfToken = sessionStorage.getItem('csrfToken');
            } else {
              csrfToken = await storageCsrf();
            }
            await deleteNoteApi(id, csrfToken).then((result) => {
                if(result.status === 200){
                    setNotes([]);
                    return toast(
                        <PNotify
                          title='Success'
                          icon='fas fa-check'
                          text={result.data.message}
                        />,
                        {
                          containerId: 'default',
                          className: 'notification-success',
                        }
                      );
                } else {
                    return toast(
                        <PNotify
                          title='Error'
                          icon='fas fa-check'
                          text={result.data.message}
                        />,
                        {
                          containerId: 'default',
                          className: 'notification-danger',
                        }
                      );
                }
            })
        }
    }

    return (
    <>
        <Row>
            <Col>
                <Card>
                    {notes.length ? 
                        (
                            notes.map((note) => (
                                <Note
                                key={note.id}
                                date={new Date(note.createdAt).toLocaleString()}
                                message={note.message ?? ''}
                                user={note?.user ?? ''}
                                onDeleteNote={!disableDelete ? () => handleDeleteNote(note.id) : false}
                                />
                            ))
                        ) 
                        : ('')
                    }
                </Card>
            </Col>
        </Row>
        <Form
            className='notes-form'
            action='#'
            method='post'
            onSubmit={addNotes}
        >
            <Row>
                <Col>
                    <Card className='card-modern card-big-info'>
                        <Card.Body>
                            <Row>
                                <Col lg='2-5' xl='1-5'>
                                    <i className='card-big-info-icon bx bx-note'></i>
                                    <h2 className='card-big-info-title'>Note</h2>
                                    <p className='card-big-info-desc'>
                                    Add new note.
                                    </p>
                                </Col>
                                <Col lg='3-5' xl='4-5'>
                                <Form.Group as={Row} className='align-items-center'>
                                    <Col
                                        as={Form.Label}
                                        lg={3}
                                        xl={2}
                                        className='control-label text-lg-right mb-lg-0'
                                    >
                                        Note
                                    </Col>
                                    <Col lg={10} xl={8}>
                                        <Form.Control
                                        as='textarea'
                                        rows={ 10 }
                                        maxLength='2500'
                                        className='form-control-modern'
                                        name='note'
                                        required
                                        ></Form.Control>
                                    <Button
                                    type='submit'
                                    className='btn-px-4 py-3 d-flex align-items-end font-weight-semibold line-height-1 mt-3'
                                    variant='primary'
                                    >
                                    <i className='bx bx-save text-4 mr-2'></i> Save Note
                                    </Button>
                                    </Col>
                                </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Form>
    </>
    )
}