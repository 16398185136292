import axios from 'axios';
const API_URL = process.env.REACT_APP_API_KEY || 'http://localhost:3002/api';

export async function getLoggedUser() {
  const config = {
    method: 'get',
    url: `${API_URL}/getLoggedUser`,
    withCredentials: true,
  };
  return await axios(config)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      alert(error);
      return error;
    });
}
export async function storageCsrf() {
  const config = {
    method: 'get',
    url: `${API_URL}`,
    withCredentials: true,
  };
  return await axios(config)
    .then((result) => {
      if (result && result.data.csrfToken) {
        sessionStorage.setItem('csrfToken', result.data.csrfToken);
        return result.data.csrfToken;
      }
    })
    .catch((error) => {
      alert(error);
      return error;
    });
}

export async function login(data) {
  const config = {
    method: 'post',
    url: `${API_URL}`,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: data,
  };
  return await axios(config)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function logout(csrfToken) {
  const config = {
    method: 'patch',
    url: `${API_URL}`,
    withCredentials: true,
    headers: {
      'csrf-token': csrfToken,
    },  
  };
  return await axios(config)
    .then((result) => {
      sessionStorage.removeItem('jwt');
      return result;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function check() {
  const config = {
    method: 'get',
    url: `${API_URL}/check`,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return await axios(config)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return error.response;
    });
}