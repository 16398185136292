import React, { useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import Breadcrumb from '../common/breadcrumb';

export default function Dashboard({user}) {
  useEffect(() => {
    document.querySelector('body').classList.add('loaded');
  }, []);
  return (
    <>
      <Breadcrumb current='Dashboard' />
      <Row>
        <Col xl={5} xxl={4}>
          <Row>
            <Col className='col-12'>
              <Card>
                <Card.Body className='p-0'>
                  <div className='widget-user-info'>
                    <div className='widget-user-info-header'>
                      <h2 className='font-weight-bold text-color-dark text-5'>
                        Hello, {user.name}
                      </h2>

                      <div className='widget-user-acrostic bg-primary'>
                        <span className='font-weight-bold'>Me</span>
                      </div>
                    </div>
                    <div className='widget-user-info-body'>
                      {/* <Row>
                                                <Col className="col-auto">
                                                    <strong className="text-color-dark text-5">$19,876.02</strong>
                                                    <h3 className="text-4-1">User Balance</h3>
                                                </Col>
                                                <Col className="col-auto">
                                                    <strong className="text-color-dark text-5">637</strong>
                                                    <h3 className="text-4-1">Products</h3>
                                                </Col>
                                            </Row> */}
                      <Row>
                        <Col>
                          {/* <Button
                            as={Link}
                            className='border font-weight-semibold text-color-dark text-3 mt-4'
                            variant='light'
                            size='xl'
                            to={`${process.env.PUBLIC_URL}/pages/user-profile`}
                          >
                            View Profile
                          </Button> */}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
