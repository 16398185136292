import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import Breadcrumb from '../../common/breadcrumb';
import { storageCsrf } from '../../../api/auth';
import { createAccount } from '../../../api/user';
import { getRolesApi } from '../../../api/role';
import { toast } from 'react-toastify';
import PNotify from '../../../features/elements/p-notify';

export default function UserCreate() {
  const [roles, setRoles] = useState();
  const [pwd, setPwd] = useState('');
  const [pwdConfirm, setPwdConfirm] = useState('');

  useEffect(() => {
    getRolesApi(setRoles);

    document.querySelector('body').classList.add('loaded');
  }, [setRoles]);

  async function saveUser(event) {
    event.preventDefault();
    let csrfToken;
    if (pwd !== pwdConfirm) {
      return toast(
        <PNotify
          title='Error'
          icon='fas fa-check'
          text='Passwords must match!'
        />,
        {
          containerId: 'default',
          className: 'notification-danger',
        }
      );
    }
    if (sessionStorage.getItem('csrfToken')) {
      csrfToken = sessionStorage.getItem('csrfToken');
    } else {
      csrfToken = await storageCsrf();
    }
    const data = {
      firstName: event.target.firstName.value,
      lastName: event.target.lastName.value,
      email: event.target.email.value,
      roleId: event.target.roleId.value,
      _csrf: csrfToken,
    };
    if (pwd !== '' && pwd === pwdConfirm) {
      data.password = pwd;
    }
    const result = await createAccount(data, csrfToken);
    if (result.status === 200 && result.data.error === 0) {
      document.getElementById('myForm').reset();
      return toast(
        <PNotify
          title='Success'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-success',
        }
      );
    } else {
      return toast(
        <PNotify
          title='Error'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-danger',
        }
      );
    }
  }

  return (
    <>
      <Breadcrumb
        current='Add User'
        paths={[
          {
            name: 'Dashboard',
            url: '/dashboard',
          },
          {
            name: 'Users',
            url: '/dashboard/users',
          },
          {
            name: 'New User',
            url: '/dashboard/users/create',
          },
        ]}
      />

      <Form
        className='ensys-form'
        action='#'
        method='post'
        onSubmit={saveUser}
        id='myForm'
      >
        <Row>
          <Col>
            <Card className='card-modern card-big-info'>
              <Card.Body>
                <Row>
                  <Col lg='2-5' xl='1-5'>
                    <i className='card-big-info-icon bx bx-user-circle'></i>
                    <h2 className='card-big-info-title'>Account Info</h2>
                    <p className='card-big-info-desc'>
                      Add here the user account info.
                    </p>
                  </Col>
                  <Col lg='3-5' xl='4-5'>
                  <Form.Group as={Row} className='align-items-center'>
                      <Col
                        as={Form.Label}
                        lg={5}
                        xl={3}
                        className='control-label text-lg-right mb-lg-0'
                      >
                        First Name
                      </Col>
                      <Col lg={7} xl={6}>
                        <Form.Control
                          type='text'
                          maxLength='30'
                          className='form-control-modern'
                          name='firstName'
                          required
                        />
                      </Col>
                    </Form.Group>
                  <Form.Group as={Row} className='align-items-center'>
                      <Col
                        as={Form.Label}
                        lg={5}
                        xl={3}
                        className='control-label text-lg-right mb-lg-0'
                      >
                        Last Name
                      </Col>
                      <Col lg={7} xl={6}>
                        <Form.Control
                          type='text'
                          maxLength='30'
                          className='form-control-modern'
                          name='lastName'
                          required
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className='align-items-center'>
                      <Col
                        as={Form.Label}
                        lg={5}
                        xl={3}
                        className='control-label text-lg-right mb-lg-0'
                      >
                        Email
                      </Col>
                      <Col lg={7} xl={6}>
                        <Form.Control
                          type='email'
                          maxLength='30'
                          className='form-control-modern'
                          name='email'
                          required
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className='align-items-center'>
                      <Col
                        as={Form.Label}
                        lg={5}
                        xl={3}
                        className='control-label text-lg-right mb-lg-0'
                      >
                        Password
                      </Col>
                      <Col lg={7} xl={6}>
                        <Form.Control
                          type='password'
                          maxLength='30'
                          className='form-control-modern'
                          name='password'
                          onChange={(e) => setPwd(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className='align-items-center'>
                      <Col
                        as={Form.Label}
                        lg={5}
                        xl={3}
                        className='control-label text-lg-right mb-lg-0'
                      >
                        Password Confirm
                      </Col>
                      <Col lg={7} xl={6}>
                        <Form.Control
                          type='password'
                          maxLength='30'
                          className='form-control-modern'
                          name='confirmPassword'
                          onChange={(e) => setPwdConfirm(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className='align-items-center'>
                      <Col
                        as={Form.Label}
                        lg={5}
                        xl={3}
                        className='control-label text-lg-right mb-lg-0'
                      >
                        Manage Role
                      </Col>
                      <Col lg={7} xl={6}>
                        <Form.Control
                          as='select'
                          className='form-control-modern'
                          name='roleId'
                        >
                          {roles
                            ? roles.map((data) => {
                                return (
                                  <option key={data.name} value={data.id}>
                                    {data.name}
                                  </option>
                                );
                              })
                            : ''}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className='action-buttons'>
          <Col md='auto' className='col-12'>
            <Button
              type='submit'
              className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
              variant='primary'
            >
              <i className='bx bx-save text-4 mr-2'></i>Save User
            </Button>
          </Col>
          <Col md='auto' className='col-12 px-md-0 mt-3 mt-md-0'>
            <Button
              as={Link}
              to={`${process.env.PUBLIC_URL}/dashboard/users`}
              className='btn-px-4 py-3 border font-weight-semibold text-color-dark line-height-1 d-flex h-100 align-items-center'
              variant='light'
            >
              Back
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
