import React, { useEffect, useState } from 'react';
import Modal from "react-modal";
import { Row, Col, Card, Button, Form } from 'react-bootstrap';

const modalStyles = {
    overlay: {
        background: 'rgba(11, 11, 11, .8)',
        zIndex: 9999
    },
    content: {
        left: '50%',
        top: '50%',
        bottom: 'auto',
        right: 'auto',
        outline: 'none',
        width: '80%',
        padding: 0,
        transform: 'translate(-50%, -50%)'
    }
};
Modal.setAppElement('#app');


export default function QuestionDetailModal({ isOpen, onClose, question, categories }) {

    const [categoryId, setCategoryId] = useState([]);
    const [questionText, setQuestionsText] = useState('');
    const [questionOrder, setQuestionOrder] = useState('');

    function closeModal(e, result) {
        e.preventDefault();
        const questionUpdateData = {
            questionCategoryId: categoryId,
            question: questionText,
            order: questionOrder,
        }
        onClose(result, questionUpdateData, 'question');
    }

    useEffect((e) => {
        setCategoryId(question?.category?.id);
        setQuestionsText(question?.question);
        setQuestionOrder(question?.order);
    }, [question]);

    return (
        <Modal
            isOpen={isOpen}
            style={modalStyles}
            onRequestClose={(e) => closeModal(e, false)}
        >
            <Card>
                <Card.Header>
                    <Card.Title>Edit Question</Card.Title>
                </Card.Header>
                <Card.Body>
                    <div className="modal-wrapper">
                        <Row>
                            <Col lg={4} xl={4} className='pl-5'>
                                <h2 className='card-big-info-title'>Question Details</h2>
                                <p className='card-big-info-desc'>
                                    Add here the Question info.
                                </p>
                            </Col>
                            <Col lg={5} xl={7}>
                                <Form.Group as={Row} className='align-items-center'>
                                    <Col
                                        as={Form.Label}
                                        lg={4}
                                        xl={2}
                                        className='control-label text-lg-right mb-lg-0'
                                    >
                                        Intrebare
                                    </Col>
                                    <Col lg={8} xl={8}>
                                        <Form.Control
                                            as='textarea'
                                            className='form-control-modern'
                                            rows={5}
                                            maxLength='2500'
                                            name='question'
                                            defaultValue={question?.question ?? ''}
                                            onChange={(e) => {
                                                setQuestionsText(e.target.value);
                                            }}
                                            required
                                        ></Form.Control>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='align-items-center'>
                                    <Col
                                        as={Form.Label}
                                        lg={4}
                                        xl={2}
                                        className='control-label text-lg-right mb-lg-0'
                                    >
                                        Categorie
                                    </Col>
                                    <Col lg={7} xl={6}>
                                        <Form.Control
                                            as='select'
                                            className='form-control-modern'
                                            name='questionCategory'
                                            onChange={(e) => {
                                                setCategoryId(e.target.value);
                                            }}
                                            defaultValue={question?.category?.id}
                                            required
                                        >
                                            <option value="">Select Category...</option>
                                            {categories
                                                ? categories.map((category) => {
                                                    return (
                                                        <option key={category.id} value={category.id}>
                                                            {category.name}
                                                        </option>
                                                    );
                                                })
                                                : ''
                                            }
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='align-items-center'>
                                    <Col
                                        as={Form.Label}
                                        lg={4}
                                        xl={2}
                                        className='control-label text-lg-right mb-lg-0'
                                    >
                                        Ordine
                                    </Col>
                                    <Col lg={8} xl={8}>
                                        <Form.Control
                                            type='number'
                                            className='form-control-modern'
                                            name='order'
                                            defaultValue={question?.order ?? null}
                                            onChange={(e) => {
                                                setQuestionOrder(e.target.value);
                                            }}
                                            required
                                        ></Form.Control>
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col md={12} className="text-right">
                            <Button
                                className="modal-confirm mr-2"
                                variant="primary"
                                onClick={(e) => closeModal(e, true)}
                            >{question ? 'Update' : 'Add'}</Button>
                            <Button
                                className="modal-dismiss"
                                variant="default"
                                onClick={(e) => closeModal(e, false)}
                            >Cancel</Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Modal>
    )
}