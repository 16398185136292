import ReactDOM from 'react-dom';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignIn from './components/pages/others/sign-in';
import Layout from './components/layout';
import Dashboard from './components/pages/dashboard';
import UserList from './components/pages/users/user-list';
import UserCreate from './components/pages/users/user-create';
import RoleList from './components/pages/roles/role-list';
import RoleCreate from './components/pages/roles/role-create';
import UserDetail from './components/pages/users/user-detail';
import RoleDetail from './components/pages/roles/role-detail';
import ContactList from './components/pages/contacts/contact-list';
import ActivityLogsList from './components/pages/activityLogs/activity-logs-list';
import ContactDetail from './components/pages/contacts/contact-detail';
import ContactCreate from './components/pages/contacts/contact-create';
import Logout from './components/pages/others/logout';
import { getLoggedUser } from './api/auth';
import LeadsElectricUpList from './components/pages/electricUp/leads-electric-up-list';
import LeadsGeneralList from './components/pages/general/leads-general-list';
import NomenclatureList from './components/pages/nomenclature/nomenclature-list';
import NomenclatureCreate from './components/pages/nomenclature/nomenclature-create';
import NomenclatureDetail from './components/pages/nomenclature/nomenclature-detail';
import StatusList from './components/pages/statuses/status-list';
import StatusDetail from './components/pages/statuses/status-detail';
import StatusCreate from './components/pages/statuses/status-create';
import ConfiguratorList from './components/pages/configurator/configurator-list';
import ConfiguratorRezidentialList from './components/pages/configurator/configurator-rezidential-list';
import ConfiguratorAgroList from './components/pages/configurator/configurator-agro-list';
import ConfiguratorComercialList from './components/pages/configurator/configurator-comercial-list';
import ConfiguratorIndustrialList from './components/pages/configurator/configurator-industrial-list';
import ConfiguratorPublicList from './components/pages/configurator/configurator-public-list';
import PageNotFound from './components/pages/others/page-not-found';
import LeadsElectricUpDetail from './components/pages/electricUp/leads-electric-up-detail';
import UserContext from './utils/context/userContext';
import QuestionsList from './components/pages/questions/question-administration-list';

function App() {
  const [user, setUser] = useState('');

  useEffect(() => {
    async function getUser() {
      const result = await getLoggedUser();
      if (result.status === 200) {
        setUser(result.data);
      }
    }

    if (!user && localStorage.getItem('isLoggedIn') === 'true') {
      getUser();
    }
  }, [user]);

  return (
    <>
      <React.StrictMode>
        <Router>
        <UserContext.Provider value={user}>
          <Routes>
            <Route path='/'>
              <Route index element={<SignIn />} />
              <Route path='/login/' element={<SignIn />} />
              <Route path='/logout' element={<Logout />} />
              {/* <Route path='/forgot-password' element={<ForgotPassword />} /> */}
              {user ? 
                <Route path='/dashboard' element={<Layout user={user}/>}>
                  <Route index element={<Dashboard user={user}/>} />
                  <Route path='users' element={<UserList />} />
                  <Route path='users/create' element={<UserCreate />} />
                  <Route path='users/:id' element={<UserDetail />} />
                  <Route path='roles' element={<RoleList />} />
                  <Route path='roles/create' element={<RoleCreate />} />
                  <Route path='roles/:id' element={<RoleDetail />} />
                  <Route path='contacts/' element={<ContactList />} />
                  <Route path='contacts/:id' element={<ContactDetail />} />
                  <Route path='contacts/create' element={<ContactCreate />} />
                  <Route path='nomenclature' element={<NomenclatureList />} />
                  <Route path='nomenclature/create' element={<NomenclatureCreate />} />
                  <Route path='nomenclature/:id' element={<NomenclatureDetail />} />
                  <Route path='electric-up/leads' element={<LeadsElectricUpList />} />
                  <Route path='electric-up/leads/:id' element={<LeadsElectricUpDetail />} />
                  <Route path='general/leads' element={<LeadsGeneralList />} />
                  <Route path='logs' element={<ActivityLogsList />} />
                  <Route path='status' element={<StatusList />} />
                  <Route path='status/:id' element={<StatusDetail />} />
                  <Route path='status/create' element={<StatusCreate />} />
                  <Route path='configurator' element={<ConfiguratorList />} />
                  <Route path='configurator/rezidential' element={<ConfiguratorRezidentialList />} />
                  <Route path='configurator/comercial' element={<ConfiguratorComercialList />} />
                  <Route path='configurator/agro' element={<ConfiguratorAgroList />} />
                  <Route path='configurator/industrial' element={<ConfiguratorIndustrialList />} />
                  <Route path='configurator/public' element={<ConfiguratorPublicList />} />
                  <Route path='questions' element={<QuestionsList />} />
                  <Route path='*' element={<PageNotFound />} />
                </Route>
                : 
                <Route path='/dashboard' element={<Layout user={user}/>}>
                  <Route path='*' element={<PageNotFound />} />
                </Route>
                }
            </Route>
          </Routes>
          </UserContext.Provider>
        </Router>
      </React.StrictMode>
  </>
  );
}

ReactDOM.render(<App />, document.getElementById('app'));
