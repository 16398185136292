import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Breadcrumb from '../../common/breadcrumb';
import DeleteConfirmModal from '../../../features/modals/delete-confirm-modal';
import Loader from '../../../features/loader';
import PNotify from '../../../features/elements/p-notify';

import { storageCsrf } from '../../../api/auth';
import { deleteStatusApi, updateStatusApi, getStatus } from '../../../api/status';

export default function StatusDetail() {
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getStatus(id).then((result) => {
      setStatus(result.data.message);
      setLoading(false);
    });
    document.querySelector('body').classList.add('loaded');
  }, [id, setStatus]);

  async function updateStatus(e) {
    e.preventDefault();
    let csrfToken;
    if (sessionStorage.getItem('csrfToken')) {
      csrfToken = sessionStorage.getItem('csrfToken');
    } else {
      csrfToken = await storageCsrf();
    }
    let data = {
        name: status.name,
        tableName: status.tableName,
        icon: status.icon,
        order: status.order,
      _csrf: csrfToken,
    };

    const result = await updateStatusApi(data, id, csrfToken);
    if (result.status === 200 && result.data.error === 0) {
      navigate(`${process.env.PUBLIC_URL}/dashboard/status`);
      return toast(
        <PNotify
          title='Success'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-success',
        }
      );
    } else {
      return toast(
        <PNotify
          title='Error'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-danger',
        }
      );
    }
  }

  function deleteStatus(e) {
    e.preventDefault();
    setOpenModal(true);
  }

  async function deleteConfirm(result) {
    setOpenModal(false);
    if (result) {
      navigate(`${process.env.PUBLIC_URL}/dashboard/status`);
      let csrfToken;
      if (sessionStorage.getItem('csrfToken')) {
        csrfToken = sessionStorage.getItem('csrfToken');
      } else {
        csrfToken = await storageCsrf();
      }
      const checker = await deleteStatusApi(id, csrfToken);
      if (checker.status === 200 && checker.data.error === 0) {
        navigate(`${process.env.PUBLIC_URL}/dashboard/status`);
        return toast(
          <PNotify
            title='Success'
            icon='fas fa-check'
            text={checker.data.message}
          />,
          {
            containerId: 'default',
            className: 'notification-success',
          }
        );
      } else {
        return toast(
          <PNotify
            title='Error'
            icon='fas fa-check'
            text={checker.data.message}
          />,
          {
            containerId: 'default',
            className: 'notification-danger',
          }
        );
      }
    }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Breadcrumb
            current='Edit Status'
            paths={[
              {
                name: 'Home',
                url: '/',
              },
              {
                name: 'statuses',
                url: `/dashboard/status`,
              },
              {
                name: 'edit',
                url: `/edit`,
              },
            ]}
          />

          <Form
            className='ecommerce-form'
            action='#'
            method='post'
            onSubmit={updateStatus}
          >
            <Row>
              <Col>
                <Card className='card-modern card-big-info'>
                  <Card.Body>
                    <Row>
                      <Col lg='2-5' xl='1-5'>
                        <i className='card-big-info-icon bx bx-user-circle'></i>
                        <h2 className='card-big-info-title'>Status Info</h2>
                        <p className='card-big-info-desc'>
                          Add here the status info.
                        </p>
                      </Col>
                      <Col lg='3-5' xl='4-5'>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Status Name
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type='text'
                              maxLength='30'
                              className='form-control-modern'
                              name='name'
                              value={status.name}
                              onChange={(e) => {
                                setStatus({ ...status, name: e.target.value });
                              }}
                              required
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Table name
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type='text'
                              maxLength='30'
                              className='form-control-modern'
                              name='tableName'
                              value={status.tableName}
                              onChange={(e) => {
                                setStatus({ ...status, tableName: e.target.value });
                              }}
                              required
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Icon
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type='text'
                              maxLength='30'
                              className='form-control-modern'
                              name='icon'
                              value={status.icon ?? ''}
                              onChange={(e) => {
                                setStatus({ ...status, icon: e.target.value });
                              }}
                              required
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Order
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type='number'
                              maxLength='30'
                              className='form-control-modern'
                              name='order'
                              value={status.order}
                              onChange={(e) => {
                                setStatus({ ...status, order: e.target.value });
                              }}
                              required
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className='action-buttons'>
              <Col md='auto' className='col-6'>
                <Button
                  type='submit'
                  className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
                  variant='primary'
                >
                  <i className='bx bx-save text-4 mr-2'></i> Save Status
                </Button>
              </Col>
              <Col md='auto' className='col-6 px-md-0 mt-0'>
                <Button
                  as={Link}
                  to={`${process.env.PUBLIC_URL}/dashboard/status`}
                  className='btn-px-4 py-3 border font-weight-semibold text-color-dark line-height-1 d-flex h-100 align-items-center'
                  variant='light'
                >
                  Back
                </Button>
              </Col>
              <Col md='auto' className='col-6 ml-md-auto mt-3 mt-md-0'>
                <Button
                  href='#delete'
                  className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
                  variant='danger'
                  onClick={deleteStatus}
                >
                  <i className='bx bx-trash text-4 mr-2'></i> Delete Status
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}

      <DeleteConfirmModal isOpen={openModal} onClose={deleteConfirm} />
    </>
  );
}
