import axios from 'axios';
import { sortByAB } from '../utils/data/other';

const API_URL = process.env.REACT_APP_API_KEY || 'http://localhost:3002/api';

export async function getConfiguratorsApi(setConfigurators) {
  const result = await getConfigurators();
  if (result.status === 200) {
    setConfigurators(result.data.message);
  }
}

export async function getConfiguratorsWithFilter(from = 0, to, filters, sortBy, listType = null) {
  const config = {
    method: 'get',
    withCredentials: true,
  };
  config.url = !listType ? `${API_URL}/configurators` : `${API_URL}/configurators/categories/${listType}`
  return axios(config)
    .then((response) => {
      let error = response?.data?.error ?? false;
      if(!error){
        let results = response.data.message;
        filters &&
          filters.forEach((filter) => {
            results = results.filter((product) => {
              if (filter.id === 'category') {
                return product.projectCategory.name === filter.value
              }
              return (
                (product.first_name + ' ' + product.last_name).search(new RegExp(filter.value, 'i')) >= 0 ||
                (product.projectCategory.name && product.projectCategory.name.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.quizAnswer.amount && product.quizAnswer.amount.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.quizAnswer.batteriesOptIn && product.quizAnswer.batteriesOptIn.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.quizAnswer.companyProfile && product.quizAnswer.companyProfile.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.quizAnswer.configuration && product.quizAnswer.configuration.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.quizAnswer.electricalSystem && product.quizAnswer.electricalSystem.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.quizAnswer.kWh && product.quizAnswer.kWh.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.quizAnswer.bridgingType && product.quizAnswer.bridgingType.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.quizAnswer.mountingSurface && product.quizAnswer.mountingSurface.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.quizAnswer.mountingType && product.quizAnswer.mountingType.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.quizAnswer.numberOfFloors && product.quizAnswer.numberOfFloors.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.quizAnswer.numberOfPeople && product.quizAnswer.numberOfPeople === parseInt(filter.value)) ||
                (product.quizAnswer.otherInfos && product.quizAnswer.otherInfos.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.quizAnswer.timeOfDay && product.quizAnswer.timeOfDay.search(new RegExp(filter.value, 'i')) >= 0)
              );
            });
          });
  
        sortBy &&
          sortBy.forEach((sort) => {
            let index = sort.desc ? -1 : 1;
            switch (sort.id) {
              case 'contact':
                results = results.sort((a, b) =>{
                  let nameA = a.contact.lastName + " " + a.contact.firstName
                  let nameB = b.contact.lastName + " " + b.contact.firstName
                  return sortByAB(nameA, nameB, index, sort)
              });
              break;
              case 'projectCategory.name':
                results = results.sort((a, b) =>
                  a.projectCategory.name < b.projectCategory.name ? -index : index
                );
                break;
              case 'quizAnswer.amount':
                results = results.sort((a, b) =>
                  a.quizAnswer.amount < b.quizAnswer.amount ? -index : index
                );
                break;
              case 'quizAnswer.batteriesOptIn':
                results = results.sort((a, b) =>
                  a.quizAnswer.batteriesOptIn < b.quizAnswer.batteriesOptIn ? -index : index
                );
                break;
              case 'quizAnswer.companyProfile':
                results = results.sort((a, b) =>
                  a.quizAnswer.companyProfile < b.quizAnswer.companyProfile ? -index : index
                );
                break;
              case 'quizAnswer.configuration':
                results = results.sort((a, b) =>
                  a.quizAnswer.configuration < b.quizAnswer.configuration ? -index : index
                );
                break;
              case 'quizAnswer.electricalSystem':
                results = results.sort((a, b) =>
                  a.quizAnswer.electricalSystem < b.quizAnswer.electricalSystem ? -index : index
                );
                break;
              case 'quizAnswer.kWh':
                results = results.sort((a, b) =>
                  a.quizAnswer.kWh < b.quizAnswer.kWh ? -index : index
                );
                break;
              case 'quizAnswer.bridgingType':
                results = results.sort((a, b) => sortByAB(a.bridgingType, b.bridgingType, index, sort)
              );
              case 'quizAnswer.mountingSurface':
                results = results.sort((a, b) =>
                  a.quizAnswer.mountingSurface < b.quizAnswer.mountingSurface ? -index : index
                );
                break;
              case 'quizAnswer.mountingType':
                results = results.sort((a, b) =>
                  a.quizAnswer.mountingType < b.quizAnswer.mountingType ? -index : index
                );
                break;
              case 'quizAnswer.numberOfFloors':
                results = results.sort((a, b) =>
                  a.quizAnswer.numberOfFloors < b.quizAnswer.numberOfFloors ? -index : index
                );
                break;
              case 'quizAnswer.numberOfPeople':
                results = results.sort((a, b) =>
                  a.quizAnswer.numberOfPeople < b.quizAnswer.numberOfPeople ? -index : index
                );
                break;
              case 'quizAnswer.otherInfos':
                results = results.sort((a, b) =>
                  a.quizAnswer.otherInfos < b.quizAnswer.otherInfos ? -index : index
                );
                break;
              case 'quizAnswer.timeOfDay':
                results = results.sort((a, b) =>
                  a.quizAnswer.timeOfDay < b.quizAnswer.timeOfDay ? -index : index
                );
                break;
              default:
                break;
            }
          });
  
        return {
          data: results.slice(from, to),
          total: results.length,
        };
      }
    })
    .catch((error) => console.error(error));
}

export async function createConfigurator(data) {
  const config = {
    method: 'post',
    url: `${API_URL}/configurators`,
    withCredentials: true,
    data: data,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function updateConfiguratorApi(data, id, csrfToken) {
  const config = {
    method: 'patch',
    url: `${API_URL}/configurators/${id}`,
    withCredentials: true,
    data: data,
    headers: {
      'csrf-token': csrfToken,
    },
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function deleteConfigurator(id, csrfToken) {
  const config = {
    method: 'delete',
    url: `${API_URL}/configurators/${id}`,
    withCredentials: true,
    headers: {
      'csrf-token': csrfToken,
    },
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function getConfigurators() {
  const config = {
    method: 'get',
    url: `${API_URL}/configurators`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}
  
export async function getConfigurator(id) {
  const config = {
    method: 'get',
    url: `${API_URL}/configurators/${id}`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}
