import axios from 'axios';

const API_URL = process.env.REACT_APP_API_KEY || 'http://localhost:3002/api';

export async function getNomenclatureWithFilter(from = 0, to, filters, sortBy) {
    const config = {
      method: 'get',
      url: `${API_URL}/nomenclature`,
      withCredentials: true,
    };
    return axios(config)
      .then((response) => {
        let error = response?.data?.error ?? false;
        if(!error){
          let results = response.data.message;
          let totalResults = response.data.message.length;
          filters &&
            filters.forEach((filter) => {
              results = results.filter((product) => {
                if (filter.id !== 'name')
                  return product[filter.id] === filter.value;
                return (
                  product['name'].search(new RegExp(filter.value, 'i')) >= 0 ||
                  product['tableName'].search(new RegExp(filter.value, 'i')) >= 0
                );
              });
            });
    
          sortBy &&
            sortBy.forEach((sort) => {
              let index = sort.desc ? -1 : 1;
              switch (sort.id) {
                case 'table_name':
                  results = results.sort((a, b) =>
                    a.level < b.level ? -index : index
                  );
                  break;
                case 'name':
                  results = results.sort((a, b) =>
                    a.name < b.name ? -index : index
                  );
                  break;
                default:
                  break;
              }
            });
    
          return {
            data: results.slice(from, to),
            total: results.length,
            totalResults: totalResults,
          };
        }
      })
      .catch((error) => console.error(error));
}

export async function createNomenclature(data) {
  const config = {
    method: 'post',
    url: `${API_URL}/nomenclature`,
    withCredentials: true,
    data: data,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function updateNomenclatureApi(data, id, csrfToken) {
  const config = {
    method: 'patch',
    url: `${API_URL}/nomenclature/${id}`,
    withCredentials: true,
    data: data,
    headers: {
      'csrf-token': csrfToken,
    },
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function deleteNomenclature(id, csrfToken) {
  const config = {
    method: 'delete',
    url: `${API_URL}/nomenclature/${id}`,
    withCredentials: true,
    headers: {
      'csrf-token': csrfToken,
    },
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function getNomenclature(id) {
  const config = {
    method: 'get',
    url: `${API_URL}/nomenclature/${id}`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}