import axios from 'axios';

const API_URL = process.env.REACT_APP_API_KEY || 'http://localhost:3002/api';

export async function getLeadsApi(setLeads) {
  const result = await getLeads();
  if (result.status === 200) {
    setLeads(result.data.message);
  }
}

export async function getLeadsWithFilter(from = 0, to, filters, sortBy) {
  const config = {
    method: 'get',
    url: `${API_URL}/contact-form/general`,
    withCredentials: true,
  };
  return axios(config)
    .then((response) => {
      let error = response?.data?.error ?? false;
      if(!error){
        let results = response.data.message;
        let totalResults = response.data.message.length;
        filters &&
          filters.forEach((filter) => {
            results = results.filter((product) => {
              return (
                (product.message && product.message.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.contact && product.contact.search(new RegExp(filter.value, 'i')) >= 0))
            });
          });
  
        sortBy &&
          sortBy.forEach((sort) => {
            let index = sort.desc ? -1 : 1;
            switch (sort.id) {
              case 'message':
                  results = results.sort((a, b) =>
                    a.firstName < b.firstName ? -index : index
                  );
                  break;
              case 'contact':
                  results = results.sort((a, b) =>{
                      let nameA = a.contact.lastName + " " + b.contact.firstname
                      let nameB = a.contact.lastName + " " + b.contact.firstname
                      // equal items sort equally
                      if (nameA.jobPosition === nameB.jobPosition) {
                          return 0;
                      }
                      // nulls sort after anything else
                      if (nameA.jobPosition === null) {
                          return index;
                      }
  
                      if (nameB.jobPosition === null) {
                          return -index;
                      }
                      // otherwise, if we're ascending, lowest sorts first
                      if (!sort.desc) {
                          return nameA.jobPosition < nameB.jobPosition ? -index : index;
                      }
                      return nameA.jobPosition < nameB.jobPosition ? index : -index;
                  });
                  break;
              default:
                break;
            }
          });
  
        return {
          data: results.slice(from, to),
          total: results.length,
          totalResults: totalResults,
        };
      }
    })
    .catch((error) => console.error(error));
}

export async function updateLeadApi(data, id, csrfToken) {
  const config = {
    method: 'patch',
    url: `${API_URL}/contact-form/general/${id}`,
    withCredentials: true,
    data: data,
    headers: {
      'csrf-token': csrfToken,
    },
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function deleteLead(id, csrfToken) {
  const config = {
    method: 'delete',
    url: `${API_URL}/contact-form/general/${id}`,
    withCredentials: true,
    headers: {
      'csrf-token': csrfToken,
    },
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function getLeads() {
  const config = {
    method: 'get',
    url: `${API_URL}/contact-form/general`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}
