import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Form, Button, Tabs, Tab } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Breadcrumb from '../../common/breadcrumb';
import DeleteConfirmModal from '../../../features/modals/delete-confirm-modal';
import Loader from '../../../features/loader';
import PNotify from '../../../features/elements/p-notify';

import { getContact } from '../../../api/contact';
import { storageCsrf } from '../../../api/auth';
import { deleteContact, getContactsApi, updateContactApi } from '../../../api/contact';
import Notes from '../../notes/notes-page';

export default function ContactDetail() {
  const [contact, setContact] = useState(null);
  const [contactOld, setContactOld] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getContactsApi(setContacts);
    getContact(id).then((result) => {
      setContact(result.data.message);
      setContactOld(result.data.message);
      setLoading(false);
    });
    document.querySelector('body').classList.add('loaded');
  }, [id, setContact, setContacts, setContactOld]);

  async function updateContact(e) {
    e.preventDefault();
    let csrfToken;
    if (sessionStorage.getItem('csrfToken')) {
      csrfToken = sessionStorage.getItem('csrfToken');
    } else {
      csrfToken = await storageCsrf();
    }
    let data = {
      _csrf: csrfToken,
    };
    if (contactOld.firstName !== e.target.firstName.value) {
      data.firstName = e.target.firstName.value;
    }
    if (contactOld.lastName !== e.target.lastName.value) {
      data.lastName = e.target.lastName.value;
    }
    if (contactOld.email !== e.target.email.value) {
      data.email = e.target.email.value;
    }
    if (contactOld.phone !== e.target.phone.value) {
      data.phone = e.target.phone.value;
    }
    if (contactOld.typeOfContactId !== e.target.typeOfContact.value) {
      data.typeOfContactId = e.target.typeOfContact.value;
    }
    if (contactOld.fullAddress !== e.target.fullAddress.value) {
        data.fullAddress = e.target.fullAddress.value;
    }
    if (contactOld.jobPosition !== e.target.jobPosition.value) {
        data.jobPosition = e.target.jobPosition.value;
    }
    if (contactOld.newsLetter !== e.target.newsLetter.checked) {
      data.newsLetter = e.target.newsLetter.checked ? true : false;
    }
    const result = await updateContactApi(data, id, csrfToken);
    if (result.status && result.status === 200 && result.data.error === 0) {
      navigate(`${process.env.PUBLIC_URL}/dashboard/contacts`);
      return toast(
        <PNotify
          title='Success'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-success',
        }
      );
    } else {
      return toast(
        <PNotify
          title='Error'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-danger',
        }
      );
    }
  }

  function deleteContactApi(e) {
    e.preventDefault();
    setOpenModal(true);
  }

  async function deleteConfirm(result) {
    setOpenModal(false);
    if (result) {
      navigate(`${process.env.PUBLIC_URL}/dashboard/contacts`);
      let csrfToken;
      if (sessionStorage.getItem('csrfToken')) {
        csrfToken = sessionStorage.getItem('csrfToken');
      } else {
        csrfToken = await storageCsrf();
      }
      const checker = await deleteContact(id, csrfToken);
      if (checker.status === 200 && checker.data.error === 0) {
        navigate(`${process.env.PUBLIC_URL}/dashboard/contacts`);
        return toast(
          <PNotify
            title='Success'
            icon='fas fa-check'
            text={checker.data.message}
          />,
          {
            containerId: 'default',
            className: 'notification-success',
          }
        );
      } else {
        return toast(
          <PNotify
            title='Error'
            icon='fas fa-check'
            text={checker.data.message}
          />,
          {
            containerId: 'default',
            className: 'notification-danger',
          }
        );
      }
    }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
        <Tabs defaultActiveKey={'general'} unmountOnExit={false} mountOnEnter={true} id={'generalTab'} className={'mb-3'} variant={'pills'}>
          <Tab eventKey={'general'} title={'General'}>
          <Breadcrumb
            current='Edit Contact'
            paths={[
              {
                name: 'Home',
                url: '/',
              },
              {
                name: 'Contacts',
                url: `/dashboard/contacts`,
              },
              {
                name: 'edit',
                url: `#`,
              },
            ]}
          />

          <Form
            className='ecommerce-form'
            action='#'
            method='post'
            onSubmit={updateContact}
          >
            <Row>
              <Col>
                <Card className='card-modern card-big-info'>
                  <Card.Body>
                    <Row>
                      <Col lg='2-5' xl='1-5'>
                        <i className='card-big-info-icon bx bx-user-circle'></i>
                        <h2 className='card-big-info-title'>Contact Info</h2>
                        <p className='card-big-info-desc'>
                          Add here the Contact info.
                        </p>
                      </Col>
                      <Col lg='3-5' xl='4-5'>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Nume
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type='text'
                              maxLength='30'
                              className='form-control-modern'
                              name='firstName'
                              value={contact.firstName}
                              onChange={(e) => {
                                setContact({ ...contact, firstName: e.target.value });
                              }}
                              required
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Prenume
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type='text'
                              maxLength='30'
                              className='form-control-modern'
                              name='lastName'
                              value={contact.lastName}
                              onChange={(e) => {
                                setContact({ ...contact, lastName: e.target.value });
                              }}
                              required
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Adresa
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type='text'
                              maxLength='120'
                              className='form-control-modern'
                              name='fullAddress'
                              value={contact.fullAddress}
                              onChange={(e) => {
                                setContact({ ...contact, fullAddress: e.target.value });
                              }}
                              required
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            E-mail
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type='text'
                              maxLength='30'
                              className='form-control-modern'
                              name='email'
                              value={contact.email}
                              onChange={(e) => {
                                setContact({ ...contact, email: e.target.value });
                              }}
                              required
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Telefon
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type='text'
                              maxLength='30'
                              className='form-control-modern'
                              name='phone'
                              value={contact.phone}
                              onChange={(e) => {
                                setContact({ ...contact, phone: e.target.value });
                              }}
                              required
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Persoană Fizică
                          </Col>
                          <Col lg={1} xl={1}>
                            <Form.Control
                              id='persFizica'
                              type='radio'
                              name='typeOfContact'
                              value='persoana fizica'
                              onChange={(e) => {
                                setContact({ ...contact, typeOfContact: e.target.checked });
                              }}
                              defaultChecked={contact && contact.nomenclature?.name === 'persoana fizica' ? true : ""}
                            ></Form.Control>
                          </Col>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Persoană Juridică
                          </Col>
                          <Col lg={1} xl={1}>
                            <Form.Control
                              id='persJuridica'
                              type='radio'
                              name='typeOfContact'
                              value='persoana juridica'
                              onChange={(e) => {
                                setContact({ ...contact, typeOfContact: e.target.checked });
                              }}
                              defaultChecked={contact && contact.nomenclature?.name === 'persoana juridica' ? true : ""}
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Functie
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type='text'
                              maxLength='30'
                              className='form-control-modern'
                              name='jobPosition'
                              value={contact.jobPosition}
                              onChange={(e) => {
                                setContact({ ...contact, jobPosition: e.target.value });
                              }}
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Newsletter
                          </Col>
                          <Col lg={1} xl={1}>
                            <Form.Control
                              type='checkbox'
                              maxLength='1'
                              name='newsLetter'
                              onChange={(e) => {
                                setContact({ ...contact, newsLetter: e.target.checked });
                              }}
                              checked={contact.newsLetter == 1 ? true : false}
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className='action-buttons mt-3'>
              <Col md='auto' className='col-6'>
                <Button
                  type='submit'
                  className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
                  variant='primary'
                >
                  <i className='bx bx-save text-4 mr-2'></i> Save Contact
                </Button>
              </Col>
              <Col md='auto' className='col-6 px-md-0 mt-0'>
                <Button
                  onClick={() => navigate(-1)}
                  className='btn-px-4 py-3 border font-weight-semibold text-color-dark line-height-1 d-flex h-100 align-items-center'
                  variant='light'
                >
                  Back
                </Button>
              </Col>
              <Col md='auto' className='col-6 ml-md-auto mt-3 mt-md-0'>
                <Button
                  href='#delete'
                  className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
                  variant='danger'
                  onClick={deleteContactApi}
                >
                  <i className='bx bx-trash text-4 mr-2'></i> Delete Contact
                </Button>
              </Col>
            </Row>
          </Form>
          </Tab>
          <Tab eventKey={'notes'} title={'Notes'}>
            <Breadcrumb
              current='Edit Contact'
              paths={[
                {
                  name: 'Home',
                  url: '/',
                },
                {
                  name: 'Contacts',
                  url: `/dashboard/contacts`,
                },
                {
                  name: 'notes',
                  url: `#`,
                },
              ]}
            />
            <Notes
              noteSource={contact}
            ></Notes>
          </Tab>
        </Tabs>
        </>
      )}

      <DeleteConfirmModal isOpen={openModal} onClose={deleteConfirm} />
    </>
  );
}
