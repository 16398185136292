import {roles} from "./role"

const menuData = [{
        "name": "Dashboard",
        "icon": "bx bx-home",
        "url": "/dashboard"
    },
    {
        "name": "Opportunities",
        "icon": "bx bx-briefcase",
        "url": "#",
        "role": [roles.sales, roles.electricup],
        "children": [{
            "name": "Contacts",
            "url": "#",
            "role": [roles.sales, roles.electricup],
            "children": [{
                    "name": "All Contacts",
                    "url": "/dashboard/contacts",
                    "role": [roles.sales, roles.electricup]
                },
                {
                    "name": "New Contacts",
                    "url": "/dashboard/contacts/create",
                    "role": [roles.sales]
                }
            ]
        },
        {
            "name": "Contact forms",
            "url": "#",
            "role": [roles.sales, roles.electricup],
            "children": [{
                    "name": "ElectricUp",
                    "url": "/dashboard/electric-up/leads",
                    "role": [roles.electricup , roles.electricup]
                },
                {
                    "name": "General",
                    "url": "/dashboard/general/leads",
                    "role": [roles.sales]
                }
            ]
        },
        {
            "name": "Configurator",
            "url": "/dashboard/configurator",
            "role": [roles.sales],
            "children": [
                {
                    "name": "All",
                    "url": "/dashboard/configurator",
                    "role": [roles.sales]
                },
                {
                    "name": "Rezidential",
                    "url": "/dashboard/configurator/rezidential",
                    "role": [roles.sales]
                },
                {
                    "name": "Comercial",
                    "url": "/dashboard/configurator/comercial",
                    "role": [roles.sales]
                },
                {
                    "name": "Agro",
                    "url": "/dashboard/configurator/agro",
                    "role": [roles.sales]
                },
                {
                    "name": "Industrial",
                    "url": "/dashboard/configurator/industrial",
                    "role": [roles.sales]
                },
                {
                    "name": "Public",
                    "url": "/dashboard/configurator/public",
                    "role": [roles.sales]
                },
            ]
        },
        // {
        //     "name": "Prospective clients",
        //     "url": "/dashboard",
        //     "role": [roles.electricup]
        // },
        // {
        //     "name": "Quoting",
        //     "url": "/dashboard",
        //     "role": [roles.electricup]
        // }, 
    ]},
    {
        "name": "Administration",
        "icon": "bx bx-cog",
        "url": "#",
        "role": [roles.superadmin, roles.admin],
        "children": [
            {
                "name": "Users",
                "url": "#",
                "role": [roles.superadmin],
                "children": [{
                        "name": "All Users",
                        "url": "/dashboard/users",
                        "role": [roles.superadmin]
                    },
                    {
                        "name": "New User",
                        "url": "/dashboard/users/create",
                        "role": [roles.superadmin]
                    }
                ]
            },
            {
                "name": "Roles",
                "url": "#",
                "role": [roles.superadmin],
                "children": [{
                        "name": "All Roles",
                        "url": "/dashboard/roles",
                        "role": [roles.superadmin]
                    },
                    {
                        "name": "New Role",
                        "url": "/dashboard/roles/create",
                        "role": [roles.superadmin]
                    }
                ]
            },
            {
                "name": "Nomenclature",
                "url": "/dashboard/nomenclature",
                "role": [roles.superadmin]
            },
            {
                "name": "Logs",
                "url": "/dashboard/logs",
                "role": [roles.superadmin]
            },
            {
                "name": "Statuses",
                "url": "#",
                "role": [roles.superadmin],
                "children": [
                    {
                    "name": "List statuses",
                    "url": "/dashboard/status",
                    "role": [roles.superadmin],
                    },
                    {
                        "name": "New statuses",
                        "url": "/dashboard/status/create",
                        "role": [roles.superadmin],
                    }
                ]
            },
            {
                "name": "Manage Questions",
                "url": "/dashboard/questions",
                "role":[roles.admin, roles.sales],
            }
        ]
    }
];

export default menuData;