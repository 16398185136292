import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { check, login, storageCsrf } from '../../../api/auth';
import { withDismissible } from '../../hoc/index';

export default function SignIn() {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const AlertDismissible = withDismissible(Alert);

  const navigate = useNavigate();

  useEffect(() => {

    async function checkJWT() {
      const result = await check();
      if (result.status === 200) {
        navigate(`${process.env.PUBLIC_URL}/dashboard`);
      } else {
        localStorage.setItem('isLoggedIn', 'false');
      }

    }

    if (!sessionStorage.getItem('csrfToken') || localStorage.getItem('isLoggedIn') !== 'true') {
      storageCsrf();
    }

    if (localStorage.getItem('isLoggedIn') === 'true') {
      checkJWT();
    }

    document.querySelector('body').classList.add('loaded');
  }, [navigate]);

  // push notify if error netwrok or login
  // return toast(
  // <PNotify
  // title='Error'
  // icon='fas fa-check'
  // text={deleteUsers.data.message}
  // />,
  // {
  // containerId: 'default',
  // className: 'notification-danger',
  // }
  // );
  // }
  async function handleSubmit(event) {
    event.persist();
    setShow(false);
    setMessage('');
    event.preventDefault();
    const { email, password, remember } = event.target;
    let csrfToken;
    if (sessionStorage.getItem('csrfToken')) {
      csrfToken = sessionStorage.getItem('csrfToken');
    } else {
      csrfToken = await storageCsrf();
    }
    const data = {
      email: email.value,
      password: password.value,
      remember: remember.checked,
      _csrf: csrfToken,
    };
    try {
      const result = await login(data);
      if (result && result.status === 200) {
        setShow(false);
        setMessage('');
        const checkResult = await check(data);
        if (checkResult && checkResult.status === 200) {
          localStorage.setItem('isLoggedIn', 'true');
          navigate(`${process.env.PUBLIC_URL}/dashboard`);
          navigate(0);
        } else {
          setMessage(checkResult.data.message);
          setShow(true);
        }
      } else {
        setMessage(result.data.message);
        setShow(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <section className='body-sign'>
      <div className='center-sign'>
        {show && message && (
          <AlertDismissible variant='danger'>{message}</AlertDismissible>
        )}
        <Card className='card-sign'>
          <Card.Body>
            <h2 className='sign-title'>Sign In</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group className='form-custom-group'>
                <Form.Label>
                  Email Address <span className='required'>*</span>
                </Form.Label>
                <Form.Control name='email' type='email' required autoFocus />
              </Form.Group>

              <Form.Group className='form-custom-group'>
                <Form.Label className='float-left'>
                  Password <span className='required'>*</span>
                </Form.Label>
                <Form.Control name='password' type='password' required />
              </Form.Group>

              {/* <Form.Control name='_csrf' type='hidden' value={csrf} /> */}

              <Row className='rem-password'>
                <Col sm={8}>
                  <Form.Check
                    name='remember'
                    className='checkbox-default'
                    label='Remember me'
                  />
                </Col>

                <Col sm={4}>
                  <Link to='/forgot-password'>Forgot Password?</Link>
                </Col>
              </Row>

              <Button type='submit' className='btn-login'>
                Sign In
              </Button>
            </Form>
          </Card.Body>
        </Card>

        <p className='text-center text-muted my-3'>
          &copy; Copyright 2021. All Rights Reserved.
        </p>
      </div>
    </section>
  );
}
